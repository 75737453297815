.giga-page-alert {
    display: block;
    margin:  0 0 10px 0;
    background-color: rgb(251, 234, 157);
    color: #000;
    padding: 10px 20px;
    font-size: 15px;
    transition: all .2s ease;
    max-height: 1000px;

    &.hidden {
        padding-top:0;
        padding-bottom: 0;
        max-height: 0;
    }

    &.error-alert {
        background-color: rgb(255, 163, 163);
        color: #000;
    }

    &.success-alert {
        background-color: #9dcc6a;
        color: #000;
    }
}