.giga-table-wrapper {
    overflow: hidden;
    overflow-x: auto;
    position: relative;
    border: solid 1px #ccc;

    ::-webkit-scrollbar-track {
        background: #fff; /* opacity: 0; should do the thing either */
    }

    ::-webkit-scrollbar-thumb {
  
        box-shadow: 0px 0px 0px 100000vh white;
      }
}

.giga-table-scroller {
    position: absolute;
    top: 26px;
    left: 0px;
    right: 0px;
    bottom: 0px;
    overflow: hidden;
    overflow-x: auto;
    overflow-y: auto;

    
}

.giga-table {
    width: 100%;
    min-width: 100%;

    * {
        box-sizing: border-box;
        background-color: #ececec;
    }

    table {
        border-collapse: collapse;
        margin: 0;
        padding: 0;
    }

    .giga-table-header > table  {
        background: #ececec;
        min-width: 100%;
    }

    .giga-table-header > table ,
    .giga-table-rows > table {
        min-width: 100%;

        th,td {
            padding: 3px 0 3px 5px;
            font-size: 0.78em;
            line-height: 1.2;
            border-right: solid 1px rgb(226, 226, 226);
            border-bottom: solid 1px rgb(226, 226, 226);

            span {
                background-color: inherit;
            }
        }

        th {
            position: relative;
            height: 25px;
            padding: 2px 3px 0 5px;
            border-color: rgb(168, 168, 168);
            background: #ececec;
            font-weight: 400;
            cursor: default;

            span {
                display: inline-block;
                position: relative;

                sup {
                    font-size: 9px;
                    position: absolute;
                    right: -12px;
                    top: 0px;
                    color: #464646;
                }
            }
        }

        td {
            color: #1f1f1f;
            border-color: #e6e6e6;
            // color: #466580;
            color: #575c61;
            padding: 3px 3px 3px 5px;
            cursor: default;

            * {
                background-color: inherit;
            }

            &:nth-child(1) {
                position: sticky;
            }

            .truncate {
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;
                display: block;
                position: relative;
            }

            input:not([type="checkbox"]){
                border: solid 1px #104bef;
                height: 19px;
                width: 100%;
            }
            
            button {
                background-color: inherit;
                cursor: pointer;
                padding:0;
                margin:0;
                border:none;
                outline: none;

                &:active {
                    color: white;
                }
            }
        }
    }

    .giga-table-header {
        width: 100%;
        tr {

            -webkit-touch-callout: none; /* iOS Safari */
            -webkit-user-select: none; /* Safari */
            -khtml-user-select: none; /* Konqueror HTML */
            -moz-user-select: none; /* Old versions of Firefox */
                -ms-user-select: none; /* Internet Explorer/Edge */
                    user-select: none;


            th.giga-td-last-fixed {
                border-right-color: #333;
            }

            th.giga-fixed-td {
                background-color: #d6d6d6;
            }

            th.dragged {
                z-index: 10;
                position: relative;
                background-color: rgb(255, 255, 255);
                opacity: 0.6;

                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    left:-30px;
                    right:-30px;
                    height: 100px;
                    z-index: 10;
                }

                &:after {
                    bottom: -40px;
                }
            }

            th.dragover {
                position: relative;
                background-color: rgb(195, 246, 255);
                &:after {
                    content: "";
                    display: block;
                    position: absolute;
                    top:0px;
                    bottom:0px;
                    right:-1px;
                    width: 3px;
                    background-color: red;
                }
            }

            th.giga-td-last-fixed:after {
                content: "";
                display: block;
                position: absolute;
                right:-1px;
                top:0px;
                bottom: 0px;
                width: 1px;
                background-color: #b1b1b1;
            }

            .sort-icon {
                display: block;
                position: absolute;
                right: 6px;
                top:5px;
                cursor: pointer;
                color: #d63737;

                &.sort-hidden { display: none;
                   
                }


                &:before {
                    content: "\f07d";
                    font-family: FontAwesome;
                }

                &.sort-asc {
                    display: block;
                    &:before {
                        content: "\f0d7";   
                    }
                }

                &.sort-desc {
                    display: block;
                    &:before {
                        content: "\f0d8";   
                    }
                }
            }

            th:hover {
                .sort-icon {
                    &.sort-hidden {
                        display: block;
                        &:hover {
                            color: #000;
                        }
                    }
                }
            }
        }
    }

    .giga-table-rows {
        width:100%;
        overflow: hidden;

        tr {
            &:nth-child(odd){
                td {
                    background-color: #f1f1f1;

                    &.giga-fixed-td {
                        background-color: #ececec;
                    }
                }

            }

            &:nth-child(even){
                td {
                    background-color: #fff;

                    &.giga-fixed-td {
                        background-color: #fff;
                    }
                }
            }



            &.active {
                td {
                    background-color: #179ffa  !important;
                    color: #fff !important;
                }
            }


            &:hover {
                td {
                    background-color: #46b5ff !important;
                    color: #fff  !important;
                }
            }       


            &.selected:hover,
            &.selected {
                td {
                    background-color: #e06a6a  !important;
                    color: #fff !important;
                }
            }

            td.giga-td-last-fixed {
                border-right-color: #333;
            }

            td.giga-td-first-fixed:before {
                content: "";
                display: block;
                position: absolute;
                left:-1px;
                top:0px;
                bottom: 0px;
                width: 1px;
                background-color: #a7a7a7;
            }

            td.giga-td-last-fixed:after {
                content: "";
                display: block;
                position: absolute;
                right:-1px;
                top:0px;
                bottom: 0px;
                width: 1px;
                background-color: #b1b1b1;
            }
        }
    }
}

.giga-table-buttons {
    padding: 5px 10px;
    border: solid 1px #ccc;
    border-bottom: none;
    background: #ececec;

    h4 {
        font-size: 13px;
        margin: 0;
        text-shadow: 1px 1px 1px #fff;
        font-weight: 600;
        color: #6f6e6b;
        background: #dcdcdc;
        margin-top: -5px;
        margin-left: -10px;
        margin-right: -10px;
        padding: 5px;
    }

    .giga-table-button {
        display: inline-block;
        padding: 5px 10px;
        border: solid 1px #ccc;
        border-radius: 3px;
        font-size: 13px;
        line-height: 13px;
        margin: 3px 3px 3px 3px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;

        &:hover {
            border-color: #999;
            box-shadow: 0px 1px 4px 0px #ccc;
        }

        &:active {
            border-color: #222;
        }
    }
}

.giga-fixed-td {
    position: relative;
    z-index: 2;
}

.giga-table-wrapper {
    .loadingspinner {
        pointer-events: none;
        width: 2.5em;
        height: 2.5em;
        border: 0.4em solid transparent;
        border-color: #eee;
        border-top-color: #3E67EC;
        border-radius: 50%;
        animation: loadingspin 1s linear infinite;
        position: absolute;
        left:50%;
        top:50%;
        margin-left: -1.25em;
        margin-top: -1.25em;
    }
    
    @keyframes loadingspin {
        100% {
                transform: rotate(360deg)
        }
    }

    .giga-table-loading {
        display: block;
        position: absolute;
        top:0px;
        left:0px;
        right:0px;
        bottom:0px;
        background-color: rgba(255,255,255,0.5);
        z-index: 5;
    }
}


.giga-table-footer {
    position: relative;
    border: solid 1px #ccc;
    border-top: none;
    padding: 10px;
    min-height: 40px;
    background-color: #f1f1f1;

    &:after {
        content: "";
        display: block;
        clear: both;
    }

    .gtf-section {
        display: inline-block;
        position: relative;
        font-size: 15px;

        &.float-right {
            float:right;
            margin-left: 20px;
        }

        input {
            padding: 5px 0;
            text-align: center;
            border: solid 1px #ccc;
            border-radius:3px;
            margin-left: 10px;
            margin-right: 10px;
        }

        .icon-btn {
            cursor: pointer;
            color:#555;

            &:hover {
                color: #000;
            }

            &.lg-icon-btn {
                border: solid 1px #ccc;
                padding: 5px;
                margin: 0 10px;
                cursor: pointer;

                &:active {
                    color: red;
                    border-color: black;
                }
            }
        }

        .fa-search.icon-btn {
            position: absolute;
            top:5px;
            right: 0px;
        }

        input.giga-table-search {
            margin-right: 30px;
            text-align: left;
        }

    }

    .gtf-loading-mask {
        display: block;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        z-index: 5;
    }
}