.gigaEditor {
    position: relative;

    .css-editor {
        display: block;
        position: absolute;
        top:40px;
        left:0;
        right:0;
        bottom: 0;
        z-index: 5;

        textarea  {
            width:100%;
            height: 100%;
        }
    }

    .gigaEditorToolbar {
        position: relative;
        padding: 5px 33px 5px 10px;
        border: solid 1px #ccc;
        min-height: 40px;

        > span {
            display: inline-block;
            padding:0;
            margin:0;
        }

        .vertical-spliter {
            display: inline-block;
            border: solid 1px #ccc;
            margin: 0px 15px;
            height: 18px;
            position: relative;
            top:4px;
        }

        .get-collection {
            display: inline-block;
            margin: 5px;
        }

        .get-btn {
            height: 32px;
            border: none;
            padding: 0px 8px;
            font-size: 15px;
            color: #000;
            background-color: #fff;
            color: #444;
            cursor: pointer;
            display: inline-block;

            &:hover {
                color: #000;
                background-color: rgb(224, 224, 224);

                label {
                    color: #000 !important;
                }
            }

            &:disabled {
                opacity: .2;
                cursor: default;
                pointer-events: none;
                background-color: #fff !important;
            }

            label {
                margin: 0 !important;
                padding: 0 !important;
                border:none !important;
                width:auto !important;
                height:auto !important;
                top:0 !important;
                font-size: 15px !important;
                cursor: pointer;
            }
        }

        .gigaEditorToolbarOptions {
            display: block;
            position: absolute;
            top:0;
            bottom:0;
            right:0;
            width: 32px;
            border-left: solid 1px #ccc;
            padding: 5px;

            >span {
                height: 100%;
                display: -webkit-box;
                display: -moz-box;
                display: -ms-flexbox;
                display: -webkit-flex;
                display: flex;
                align-items: center;
                justify-content: center;
                cursor: pointer;

                &:hover {
                    background-color: #eee;
                }

                &.opened {
                    background-color: #ddd;
                }
            }

            .geto-droplist {
                position: absolute;
                bottom: 0px;
                right:0px;
                width: 400px;
                z-index: 10;
                border: solid 1px #ccc;
                padding: 3px 10px 3px 10px;
                background-color: #fff;
                transform: translateY(100%);
                text-align: right;

                &.opened {
                    display: block;
                }
                &.closed {
                    display: none;
                }

                > span {
                    display: inline-block;
                }
            }
        }
    }

    .gigaEditorContent {
        border: solid 1px #ccc;
        border-top: none;
        font-family: Arial, Helvetica, sans-serif;
        font-size: 14px;
        line-height: 1.25;
        background-color: #fff;
        color: #222;
        padding: 10px;
        overflow: auto;
        word-break: normal;

        &:active,
        &:focus {
            outline: none;
        }

        .editor-highlight {
            background-color: red !important;
            color:#fff !important;
        }

        .theme-wrap {
            border: dotted 1px red;
        }

        a {
            color: rgb(0, 134, 243);
            cursor: pointer !important;
        }

        figure {
            line-height: 0;
            display: inline-block;
            img {
                line-height: 0;
            }

            figcaption {
                display: block !important;
                line-height: 1.3;
                font-size: 12px;
                text-align: center;
                padding: 8px 10px;
                font-style: italic;
                color: #777;
                border: solid 1px #ccc;
            }
        }

        p {
            line-height: 1.5;
            min-height: 21px;
            border: dotted 1px transparent;
            padding:0 0 15px 0;
            position: relative;

            &:after {
                content: "";
                display: block;
                clear: both;
            }

            &.active {
                border: dotted 1px #ccc;
            }

            &.temp-element {
                border-color: orange;
            }
        }

        h1,
        h2,
        h3,
        h4,
        h5,
        h6 {
            &.active {
                border: dotted 1px #ccc;
            }
        }

        template {
            display: block;
        }

        table {
            box-sizing: border-box;
            border-collapse: collapse;

            td {
                padding: 2px;
                position: relative;
            }
        }

        .element-editor.active {
            table {
                td {
                    border: dashed 1px blue !important;
                }
            }
        }

        .editor-error {
            display: block;
            padding: 20px;
            background-color: rgb(255, 191, 191);
            color:#000;
            font-size: 14px;
            line-height: 1.4;
        }

        ul {
            padding: 20px 0 20px 30px;

            li {
                list-style-type: disc;
                font-size: 85%;
            }
        }

        ol {
            padding: 20px 0 20px 30px;

            li {
                display: list-item;
                list-style-type: decimal;
                font-size: 85%;
            }
        }

        .editor-highlighted {
            box-shadow: 0px 0px 0px 1px rgb(39, 187, 255);
        }
    }

}


.GigaEditorModal {
    position: absolute;
    top: 20%;
    left: 50%;
    transform: translate(-50%, -50%);
    min-width: 400px;
    min-height: 150px;
    background-color: #ffffff;
    box-shadow: 0px 0px 7px 0px #737373;
    z-index: 999;
    padding: 5px 10px 45px 10px;

    table {
        width: 100%;
        border-collapse:collapse;
        border: none;

        td {
            padding: 5px;
        }
    }

    .giga-tabs-wrap {
        margin: 0;
        .giga-tab-btn {
            padding: 10px 22px;
        }
    }

    .ge-form-group {
        margin-bottom: 5px ;
        position: relative;
        label {
            width: 80px;
            border-bottom: none;
            top:0;
            font-size: 11px;
        }

        input.small {
            width: 60px;
        }

        input.medium {
            width: 120px;
        }

        input.largest {
            width: calc(100% - 90px) !important;
        }

        textarea {
            height: 150px;
            border: solid 1px #ccc;
            width: calc(100% - 90px) !important;
            resize: none;
            font-size: 12px;
        }
    
    }

    .modal-content {
        display: block;
        position: relative;
        max-height: 400px;
        min-width: 500px;
        overflow: hidden;
        overflow-y: auto;

        h4 {
            font-weight: 600;
            font-size: 16px;
            border-bottom: solid 1px #ccc;
            margin-bottom: 15px;
        }
    }

    .modal-foot {
        display: block;
        position: absolute;
        bottom:0px;
        left:0px;
        right:0px;
        height: 45px;
        padding: 8px 10px 0 10px;
        text-align: right;
        border-top: solid 1px #ccc;

        button {
            margin-left: 10px;
            margin-right: 5px;
            border: solid 1px rgb(146, 146, 146) !important;
            background-color: #fff;
            border-radius: 2px;

            &.primary-btn {
                background-color: #126dd6;
                border-color: #126dd6 !important;
                color: #fff;
            }
        }
    }
}

.redux-form .form-group .GigaEditorFiles .image-placeholder{
    margin:10px 20px  0px 0px;
    img{
        width:initial;
        height: initial;
        max-width: 100%;
        max-height: 100%;
    }
    
}

.editor-element-help {
    display: block;
    position: fixed;
    top:0px;
    left:0;
    transform: translateY(calc(100% - 50px));
    background-color: #fff;
    padding: 10px;
    z-index: 999;
    border: solid 1px #ababab;
    border-radius: 5px;

    &:before {
        content: "";
        display: block;
        position: absolute;
        left:50%;
        top:-10px;
        border-bottom: solid 10px #666;
        border-left: solid 10px transparent;
        border-right: solid 10px transparent;
        margin-left: -5px;
        width: 0;
        height: 0;
    }

    label {
        border: none !important;
        width: 40px !important;
        top: 0 !important;
        font-size: 11px !important;
        padding-right: 10px;
    }

    input {
        border: solid 1px #ccc;
        padding: 3px 5px;
        &.small {
            width: 45px;
        }
    }
    .editor-td-align {
        margin-top: 5px;
        text-align: center;
        border-top: solid 1px #ccc;
        padding-top: 5px;

        button {
            margin: 3px 5px;
            border: none;
            background-color: #fff;
            border-right: 3px;
            padding: 3px 6px;
            cursor: pointer;

            &:hover {
                background-color: #ddd;
            }

            &.selected,
            &:active {
                background-color: #609bde;
            }
        }
    }
}


.giga-timer {
    h4 {
        font-size: 22px !important;
        line-height: 32px;
        text-align: center;
        margin: 20px 0;
        font-weight: 400;
    }

    .giga-timer-html {
        text-align: center;

        span {
            display: inline-block;
            padding: 5px 8px;
            font-size: 13px;
            margin: 5px;

            ins {
                text-decoration: none;
                font-size: 26px;
                font-weight: 600;
                display: block;
                width: 50px;
                height: 50px;
                line-height: 26px;
                padding: 13px 5px 5px 5px;
                background-color: #fff;
                color:#000;
                border-radius: 10px;
            }
        }
    }
}

.GigaEditorFiles {

    position: relative;
    padding: 5px 0;

    &:after {
        content: "";
        display: block;
        clear: both;
    }
    
    .image-placeholder {

        position: relative;
        height: 50px;
        float: left;
        padding: 4px !important;
        margin: 0 10px 10px 0 !important;
        background-color: #fff !important;
        border: solid 1px transparent !important;
        cursor: pointer;
        transition: all 0.3s ease;

        &:hover {
            box-shadow: 0px 0px 3px 0px #555;
        }
        

        .img-container {
            height: 50px;

            img {
                max-height: 40px !important;
            }
        }

        .remove-image-placeholder {
            right: -2px !important;
            top: -4px !important;
            color: red;

            &:hover {
                color: black !important;
            }
        }
    }
}

.template-icons button {
    font-size: 10px;
    margin:5px;
}








/* tiny */ 
.rc-color-picker {
    z-index: 20000 !important;
}
span.tox-tbtn__select-label {
    font-size: 12px !important;
    display: none;
}
.tox-icon.tox-tbtn__icon-wrap {
    padding-left: 5px;
    padding-right: 5px;;
}
.tinydialog {
    display: block;
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
    min-width: 600px;
    min-height: 450px;
    box-shadow: 0px 0px 12px -1px #999;
    background-color: #fff;
    z-index: 9999;
    font-family: Arial, Helvetica, sans-serif ;
    padding-bottom: 47px;

    .tinydialogheader {
        display: block;
        position: relative;
        padding: 5px 10px;
        background-color:#e4e4e4;
        color: #333;
        height: 30px;
        
        span.tinydialogtitle {
            font-size: 14px;
            font-weight: 600;
        }
    }


    .tinydialogbody {
        position: absolute;
        overflow: hidden;
        overflow-y: auto;
        padding: 20px;
        top: 30px;
        left: 0px;
        right: 0px;
        bottom: 44px;

        form {
            .form-group {
                padding: 0 10px 10px 0;

                &.wide-form-group {
                    width: 100%;
                }

                &.padd-form-group {
                    width: 200px;
                    padding: 10px;
                    text-align: center;
                    margin-bottom: 15px;
                    border-radius: 3px;
                    label {
                        font-weight: 600;
                        color: #000;
                    }
                }

                label,.grey-font {
                    color: rgba(34,47,62,.7);
                    font-size: 12px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 1.3;
                    padding: 0 8px 0 0;
                    text-transform: none;
                }
                label{
                    white-space: nowrap;
                    display: block;
                }

                .selectfield {
                    -webkit-appearance: none;
                    -moz-appearance: none;
                    appearance: none;
                    background-color: #fff;
                    border-color: #ccc;
                    border-radius: 3px;
                    border-style: solid;
                    border-width: 1px;
                    box-shadow: none;
                    box-sizing: border-box;
                    color: #222f3e;
                    font-family: -apple-system,BlinkMacSystemFont,"Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
                    font-size: 14px;
                    line-height: 24px;
                    margin: 0;
                    min-height: 34px;
                    outline: 0;
                    padding: 5px 4.75px;
                    resize: none;
                    width: 100%;

                    &:focus {
                        background-color: #fff;
                        border-color: #207ab7;
                        box-shadow: none;
                        outline: 0;
                    }
                }
       
                .textfield {
                    appearance: none;
                    background-color: #fff;
                    border: solid 1px #ccc;
                    border-radius: 3px;
                    box-sizing: border-box;
                    box-shadow: none;
                    color: #222f3e;
                    font-family: "Segoe UI",Roboto,Oxygen-Sans,Ubuntu,Cantarell,"Helvetica Neue",sans-serif;
                    font-size: 14px;
                    line-height: 24px;
                    margin: 0;
                    min-height: 34px;
                    outline: 0;
                    padding: 5px 4.75px;
                    resize: none;
                    width: 100%;

                    &:focus {
                        background-color: #fff;
                        border-color: #207ab7;
                        box-shadow: none;
                        outline: 0;
                    }
                }
                .input_range_field{
                    padding: 20px 10px 5px 10px;
                }
            }
        }

    }


    .tinydialogfooter {
        position: absolute;
        bottom: 0px;
        left: 0px;
        right: 0px;
        text-align: right;
        padding: 10px;
        border-top: solid 1px #ccc;
        height: 47px;
        background-color: #eee;
        z-index:999999999;

        button {
            padding: 5px 20px;
            border: none;
            background-color: #8a9fb7;
            color: #fff;
            cursor: pointer;
            margin-left: 20px;

            &:hover {
                background-color: #126dd6;
            }

            &:active {
                background-color: #0a315e;
            }
        }
    }

    .clear::after {
        content: "";
        display: block;
        clear: both;
    }
    
    .row-1 > div {
        width: 100%;
        float: left;
    }
    .row-2 > div {
        width: 50%;
        float: left;
    }
    .row-3 > div {
        width: 33.333333%;
        float: left;
    }
    .row-4 > div {
        width: 25%;
        float: left;
    }
    .row-5 > div {
        width: 20%;
        float: left;
    }
}


#tinyMceEditor{ 
    .tox-toolbar-overlord{
        max-height: 40px;
        transition: max-height 0.30s ease-out;
        overflow: hidden;
    
        >:first-child{
            padding-right: 80px !important;
            position: relative;
            >:last-child{
                position: absolute;
                top: 0px;
                right: 0px;
            
            }

        }
    }
    
    .tox-toolbar-overlord.showMe {
        max-height: 1000px;
        transition: max-height 0.30s ease-in;
    }
}




///SEARCH
.tinydialog .tinydialogbody form .search-form-field{
    width: 400px;
    position: relative; 
    z-index: 9999999999;
    margin-bottom:28px;
    // font-family: Rubik;
 
    
    .search-dropdown{
        background-color: white;
        left: 0px;
        right: 10px;
        position: absolute;
        overflow-y: auto;
     
    }
    //todo fix
    .search-item{
        width: 100%;
        padding: 10px 5px 10px 0px;
        border-bottom: 1px solid #e4dfdf;
        display: flex;

        >:first-child{
            width:30%;
            height: 60px;
            position: relative;
        }
        >:nth-child(2){
            word-wrap: break-word;
            padding: 0 2px;
            width:63%;
        }
        >:nth-child(3){
            cursor: pointer;
            margin: -5px 0px;
            width: 7%;
            align-self: stretch;
            background-color: #eeee;
            display: flex;
            i{  
                align-self: center;
                display: inline-block;
                margin: auto;
                &.fa-times{
                    padding-bottom: 5px;
                }
            }
            &.block-adding:hover{
                cursor:not-allowed;
            }
            // color: grey;
            
        }
        span{
            font-weight: bold;
        }

    }

    img{
        width: auto;
        max-width: 100%;
        max-height: 60px;
        display: block;
        position: relative;
        margin:  auto;
        // padding: 5px;
    }
    p{
        font-size: 12px;
    }
 
    .not-found{
        padding: 0 5px;
        display: inline-block;

    }
    .check-wrapper{
        position: absolute;
        transform: scale(0.26);
        top: -3px;
        left: -24px;

    }
    .label-active{
        font-weight: bold;
    }
    .label-inactive{
        cursor: pointer;
    }


}

// ResizeRangeField
.resolution_wrapper_main{
    overflow-x: auto;
    background: url("https://www.poynter.org/wp-content/uploads/2019/01/background.png"); 
    background-size: 200px;
}

.resolution_wrapper{
    margin: 0px auto;
}

.resize_range_field{ 
    background: white; 
    border-top: 1px solid #e4e4e4;
    padding:15px 20px 0px 20px;
    position: fixed;
    bottom: 40px;
    left: 0px;
    right: 0px;
    z-index: 999999999999999999999999999999;
    height: 102px;
    // overflow-x: scroll; 
    >div{
        position: relative;
        bottom: -25px;
        width: 7680px;
    }
    .slider{
        background: none; 
    }
    .slider_mark{
        color: #666; 
        background: white;
        cursor: pointer!important;
    }
    .lock_slider_mark{
        &.fa-lock:hover::after{
            content:"Switch to scaling content with slider";

        }
        &.fa-unlock:hover::after{
            content:"Switch to scaling content with browser";
        }
        &.fa::after{ 
            position: absolute;
            height: fit-content;
            width:fit-content;
            white-space: nowrap;
            overflow-x: hidden;
            background: white;
            border: 1px solid #eee;
            padding: 6px 5px  6px 5px;
            left: 177px;
            top:0px;
            text-align: left;
            font-weight: normal;
            font-size: 12px;
            color: grey;
            font-family: "Liberation Sans";
        }
    }
    // .rc-slider-handle:focus{ 
    // }
    // .rc-slider-step{ 
    // }
    .rc-slider-dot:nth-child(2){
        color: #607d8b!important;
        border-color: #607d8b;
    }
    .fa-lock,.fa-unlock{
        cursor: pointer!important;
        padding: 0px 5px;
        // &:hover::before{
        //     content: "";
        // }
    }
}