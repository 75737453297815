
.giga-date-picker-wrap {
    
    position: relative;

    table {
        width: 100%;
        border-collapse:collapse;
        border: none;

        td {
            padding: 5px;
        }
    }
    
    .giga-editor-delete-date,.giga-editor-close-date{
        width: fit-content;
        margin: 5px 0px;
        padding: 5px;
        font-size: 12px;
        font-weight: 600;
        border: 1px solid #cccccc;
        border-radius: 2px;
        
    }
    .giga-editor-close-date{
        float: right;
        width: 79px;
    }

    .giga-date-picker {
        input.error {
            border: solid 1px red !important;
            height: auto !important;
        }
    }

    .giga-date-calendar {
        z-index: 999;
        background: white;
        display: block;
        position: absolute;
        bottom:0px;
        left: 50%;
        transform: translate(-50%,100%);
        padding: 10px;
        border: solid 1px #7b7b7b;
        width: 200px;

        .gdc-nav {
            display: block;
            position: absolute;
            top: -1px;
            padding: 10px;
            cursor: pointer;

            &.nav-left {
                left: 10px;
            }   

            &.nav-right {
                right: 10px;
            }

            &:hover {
                color: red;
            }
        }

        .gdc-header {
            border-bottom: solid 1px #ccc;

            span.gdc-date {
                display: block;
                font-size: 12px;
                text-align: center;
                font-weight: 600;
                padding: 0px 0;
                top: -4px;
                position: relative;

                small {
                    display: block;
                    font-weight: 400;
                }

                u {
                    text-decoration: none;
                }
            }
        }


        .gdc-body {
            
            td,
            th {
                font-size: 10px;
                text-align: center;
                padding: 4px;
                text-transform: capitalize;
            }

            td {
                font-size: 13px;
                padding: 1px;
                color:#000;
                cursor: pointer;

                &.day-week {
                    background-color: #ffe8d4;
                }

                &.outer-month {
                    background-color: #efefef;
                    color:#949494;

                    &.day-week {
                        background-color: #efefef;
                    }
                }

                span {
                    display: block;
                    padding: 3px 0;
                    line-height: 19px;
                    text-align: center;
                }

                &.active {
                    span {
                       
                        border-radius: 50%;
                        background-color: red;
                        color: #fff;
                    }
                }

                &:hover {
                    background-color: green;
                    color: #fff;

                    &.day-week,
                    &.active {
                        background-color: green;
                        color: #fff;
                        span {
                            background-color: green;
                            color: #fff;
                        }
                    }
                }
            }
        }

        .gdc-time {
            text-align: center;

            select {
                margin: 10px 10px 5px;
            }
        }
    }
}