.login-section {
    max-width: 950px;
    height: 470px;
    margin:auto;
    margin-top: 100px;
    padding: 118px 30px 40px 538px;
    // border:2px solid grey;
    border-radius: 10px;
    
    background-color: white;
    background-image: url("../../assets/img/deskLoginWorkplace.png");
    background-repeat: no-repeat;
    background-position: -126px -151px;
    box-shadow: 0px 6px 18px -5px #333;
    .form-group{
        margin-bottom: 25px;
    }
    .login-wrap {
        padding: 35px 10px 10px 10px;
    }
 
    .form-field-error{
        position: absolute;
        right: 20px;
        
    }
    button,.redux-form input.medium{
        font-size: 16px;
        width: 100%;
        padding: 10px;
    }
    button{
        border-radius: 5px;
    }
    &::before{
        content: "";
        background-color: #f0d23d;
        // #f1e179;
        position: absolute;
        height: 100%;
        width: 100%;
        top:0;
        left:0;
        z-index: -1;
    }
}
@media screen and (max-width: 1000px) {
    .login-section{
        padding-left: 400px;
        margin-left: 20px;
        margin-right: 20px;
        background-position-x: -150px;
    }
}
@media screen and (max-width: 800px) {
    .login-section{
        padding-left: 266px;
        background-position-x: -291px;
        
    }
}
@media screen and (max-width: 600px) {
    .login-section{
        margin-top: 65px;
        height: initial;
        background-image: none;
        padding:20px 15px;
 
        &::before{
            content: "";
            background-color: transparent;
            background-image: url("../../assets/img/mobLoginWorkplace.png");
            background-repeat: no-repeat;
            background-color: none;
            background-position: 50% 0px;
 
        }
        h1{
            padding-left: 10px;

        }
        .login-wrap{
            padding: 35px 10px;
        }
        .redux-form{
            padding: 0px;
        }
        button{
            border-radius: 5px;
            
        }
    }

}


 