.header {
  width: 100%;
  background-color: #000;
  position: fixed;
  top: 0;
  z-index: 1000;
  color: #fff;
}
.header__inner--wrapper {
  width: 100%;
}
.header__inner {
  padding: 10px 5px;
  display: block;
  align-items: center;
  width: 100%;
  height: 51px;
}

.control-menu {
  margin-left: 15px;
  margin-top:5px;
  float: left;
}




/* ~~~~ NAVIGATION ~~~~ */
 
.nav__ul {
  display: flex;
  justify-content: space-around;
  justify-content: space-evenly;
  flex-direction: row;

}

.nav__ul__item {
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.nav__ul__item__link {
  font-weight: 400;
  font-style: normal;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.2em;
  transition: all 0.3s;
  text-decoration: none;
  color: #ccc;
  padding: 3px 5px;
  border-radius: 4px;
}

.nav__ul__item__link:hover {
  color: #fff;
}

.nav__ul__item__link.active {
  background-color: #fff;
  color: #000;
}

/* ~~~~ LOGO ~~~~ */
.header__inner__logo {
  height: 100%;
  float: left;
  display: block;
  margin: 0px 0 0 40px;
}

.header__inner__logo--img {
  width: 100px;
  height: 30px;
  margin: auto;
}

/* ~~~~ CART ~~~~ */
.header__inner__cart-login {
  display: block;
  float: right;
}
.item-count__div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #000;
  font-family: sans-serif;
  color: #fff;
  width: 20px;
  height: 20px;
  border-radius: 90px;
  right: -5px;
  top: -10px;
  font-size: 0.9em;
  position: absolute;
}

.header__inner__login-controls {
  height: 100%;
  display: flex;
  align-items: center;
  margin-left: 10px;
  padding-right: 10px;
}

.profile-icon {
  font-size: 1.8em;
  height: 35px;
  width: 35px !important;
  border-radius: 50%;
  border: 2px solid rgba(29, 29, 29, 0.7);
  color: rgba(223, 223, 223, 0.7);
  padding: 4px;
  margin: 0 5px;
  margin: auto;
}

.sign-in-out-block {
  display: block;
  align-items: center;
  height: 28px;
}

.sign-out-icon {
  font-size: 1.5em;
  border-radius: 5px;
  border: 2px solid rgba(29, 29, 29, 0.7);
  color: rgba(223, 223, 223, 0.7);
  padding: 2px;
  margin: auto 5px;
  cursor: pointer;
}

.sign-in {
  color: rgba(223, 223, 223, 0.7);
}
.dropdown-menu {
  display: none;
}

.sign-in-out-block a,
.userProfile-icon__div a {
  height: 25px;
  width: 28px;
  display: block;
  padding: 0;
  margin: 0 20px;
  line-height: 0;
}
.sign-in-out-block svg,
.userProfile-icon__div svg {
  height: 100%;
  width: 25px;
  fill: #c3b780;
}