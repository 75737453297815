.tinydialog{
    min-width: 0 !important;
}
.slider-form-container{
    width: 100%;
    margin: 0 auto;
    .form-left{
        width: 50%;
        display: inline-block;
        min-width:200px;
    }
    .form-right{
        width: 50%;
        display: inline-block;
        min-width: 200px;
        vertical-align: top;
    }
}



.swiper-container .swiper-button-next,
.swiper-container .swiper-button-prev {
     transition: all 0.5s ease;
    // opacity: 0;
    background: rgba(40, 36, 36, 0.2);
    height: 95%;
    width: 40px;
    // top: 22px;
    outline: none;
    position: absolute;
    width: 38px;
    background: rgba(40,36,36,.2);
    z-index: 99;
    bottom: 0;
}

.swiper-container .swiper-button-next{
    right: 0px;
    opacity: 0;
    &:hover{
        opacity: 100;
        cursor: pointer;
    }
    &:after {
        content: "next";
        position: absolute;
        top: 50%;
        margin-left: 15px;
        cursor: pointer;
        font-family: swiper-icons;
        font-size: 28px;
        color: white;
        text-transform: none !important;
        letter-spacing: 0;
        font-variant: initial;
        line-height: 1;
      }
}
.swiper-container .swiper-button-prev{
    left: 0px;
    opacity: 0;
    &:hover{
        opacity: 100;
        cursor: pointer;
    }
    &:after {
        content: "prev";
        position: absolute;
        top: 50%;
        margin-left: 15px;
        cursor: pointer;
        font-family: swiper-icons;
        font-size: 28px;
        color: white;
        text-transform: none !important;
        letter-spacing: 0;
        font-variant: initial;
        line-height: 1;
      }
}
.btn{
    padding: 10px;
    border-radius: 2px;
    cursor: pointer;
}
.wrapper{
    position: relative;
    overflow: hidden;
    border: 1px solid black;
    text-align: center; 
    height: 376px;
    margin-top: 20px;
    .top-to-bottom{
        visibility: hidden;
        animation: 1s ease-out 2s 1 slideInFromTop forwards;
    }
    .bottom-to-top{
        visibility: hidden;
        animation: 1s ease-out 2s 1 slideInFromBottom forwards;
    }
    .left-to-right{ 
        animation: 1s ease-out 2s 1 slideInFromLeft forwards;
          visibility: hidden;
    }
    .right-to-left{ 
        visibility: hidden;
        animation: 1s ease-out 2s 1 slideInFromRight forwards;
    }

    .show-up{
        visibility: hidden;
        animation: 1s ease-out 2s 1 show forwards;
    }

    .fade-in{
        visibility: hidden;
        animation: 2s ease-in 2s 1 fadein forwards;
    }
    // .hide{
    //     visibility: hidden;
    // }
    .desc-container{
        flex-wrap: wrap;
        display: flex;
        // margin-left: 200px;  
        position: relative;
        align-items: center;
        justify-content: space-evenly;
        flex-direction: column;
        height: inherit;
        padding: 10px 30px 30px 0px;
      
        .slide{
            color: #000;
            // position: absolute;
            left: -100px;
            -webkit-animation: slide 0.5s forwards;
            -webkit-animation-delay: 2s;
            animation: slide 0.5s forwards;
            animation-delay: 2s;        /* 2 */
            top: 50%;                         /* 3 */
            // transform: translate(0, -50%);
         
        }
        .btn{
            padding: 10px;
            background-color: #fff;
            border-radius: 2px;
            cursor: pointer;
        }
    }
  
    .right-side{
        align-items: center;
        
    }


    .logo{
        // margin-left: auto;
        // margin-right: auto;
        position: relative;
        overflow: hidden;
        list-style: none;
        padding: 0;
        z-index: 1;
    
        height: 9%;
    }
    .product-image{
        height: 100%;
    }
    
    .inner-wrapper{
        margin: 0 auto;
        max-width: 1440px;
        height: inherit;
        .box{
            width: 50%;
            height: inherit;
            vertical-align:top;
            display: inline-block;

            .top{
                animation: 1s ease-out 0s 1 slideInFromTop;
            }
            .left{
                animation: 1s ease-out 0s 1 slideInFromLeft;
            }
        }
    }
}

.form-group{

    .txt-position-button{
        display: inline-block;
        padding: 5px 10px;
        border: solid 1px #ccc;
        border-radius: 3px;
        font-size: 13px;
        line-height: 13px;
        margin: 3px 3px 3px 3px;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
    }

    select{
        padding:5px;
        background-color: #fff;
    }
}

.title{
}
.subtitle{
    font-size: 35px;
    font-weight: bold;
}
.desc{
    font-size: 30px;
}
.oldPrice{
    font-size: 20px;
    text-decoration: line-through;
    
}
.newPrice{
    font-size: 30px;
    font-weight: 800;
}
.saved{
    display: inline-flex;
    font-size: 22px;
}
.anno{
}
@media screen and (max-width: 728px ){
    .wrapper{
        .product-image{
            height: 100%;
            width: 100%;
        }
    }
    
}
@keyframes slideInFromLeft {

    0% {
      transform: translateX(-50%);

    }
    
    100% {
      visibility: visible;
      transform: translateX(0);
        
    }
  }

  @keyframes slideInFromTop {
    0% {
      transform: translateY(-100%);
    }
    100% {
        visibility: visible;
      transform: translateY(0);
    }
  }

  @keyframes slideInFromRight {
    0% {
      transform: translateX(100%);
    }
    100% {
        visibility: visible;
      transform: translateX(0);
    }
  }

  @keyframes slideInFromBottom {
    0% {
      transform: translateY(100%);
    }
    100% {
      visibility: visible;
      transform: translateY(0);
    }
  }

  @keyframes fadein {
    from {
        opacity:0;
    }
    to {
        visibility: visible;
        opacity:1;
    }
  }
  @keyframes show {
    0% {
        transform:  scaleX(0) scaleY(0);
    }
    100% {
        visibility: visible;
        transform:  scaleX(1) scaleY(1);
    }
  }  ;