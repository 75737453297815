

.product-opt {
    // padding-left: 22px;
    h3 {
        font-weight: bold;
        background-color: #1d1d1d;
        color: #fff;
        padding: 5px;
        margin-bottom: 20px;
        clear: both;

        sup {
            display: block;
            top:-15px;
            color: #000;
        }
    }
    h4 {
        font-weight: 600;
        font-size: 14px;
        margin: 40px 0 20px 0;
    }
    .drop-list {


        margin-bottom: 40px;

        .drop-list-item {
            display: block;
            line-height: 15px;
            width: calc(100% - 65px);
            padding:10px 5px;
            border-bottom: dotted 1px #ccc;
            font-size: 13px;
            margin-right: 65px;
            padding-left: 32px;
            cursor: pointer;

            &:hover {
                text-decoration: underline;
            }

            input {
                display: block;
                position: absolute;
                left: 10px;
                top: 10px;
                margin-right: 10px;
            }
        }
    }

    .cat-container {
        height: 300px;
        overflow-y: auto;
        overflow-x: hidden;
    }

    .group-widget {
        width: 350px;
        float: left;
        overflow-x: hidden;
        overflow-y: auto;
        margin: 0 20px 20px 0;
        padding: 10px;
        box-shadow: 0px 0px 4px 0px #ccc;
        min-height: 420px;
        margin-bottom: 20px;
        background-color: #fff;

        h3 {
            background-color: #fff;
            color: #222;
        }
    }

    .item-opt {
        position: absolute;
        right: 5px;
        top:-4px;
        z-index: 2;
        bottom: 0px;

        .item-opt-btn {
            cursor: pointer;
            display: inline-block;
            margin-left: 15px;
            border: solid 1px transparent;
            border-radius: 3px;
            font-size: 14px;
            line-height: 24px;
            width: 24px;
            height: 24px;
            text-align: center;

            &:hover {
                border-color: #ccc;
            }

            &:active {
                border-color: #000;
            }

            &.item-opt-empty:hover,
            &.item-opt-empty:active,
            &.item-opt-empty {
                border-color: transparent imp !important;
                cursor: default;
                pointer-events: none;
            }
        }
    }

    .item-opt-img {
        max-width: 70px;
        max-height: 30px;
        position: absolute;
        right:0px;
        top:3px;
    }
}


.pop-opt {
    position: fixed;
    top:0px;
    left:0px;
    right:0px;
    bottom: 0px;
    z-index: 999999;
    background-color: rgba(0,0,0,0.5);

    .pop-body {
        width: 100%;
        max-width: 600px;
        background-color: #fff;
        left: 50%;
        top:50%;
        max-height: 0;
        overflow: hidden;
        position: absolute;
        transform: translate(-50%,-50%);
        transition: all 0.4s ease-in-out;
        opacity: 0;

        .pop-header {
            background-color: #4c80dd;
            color: #fff;
            height: 32px;
            padding: 5px 10px;
            font-size: 15px;
        }

        .pop-container {
            position: relative;
            height: calc(100% - 82px);
            overflow-x: hidden;
            overflow-y: auto;
            padding: 20px;
            transition: all 0.4s ease-in-out;

            h4 {
                margin: 0;
                padding: 0 0 15px 10px;
            }

        }

        .pop-footer {
            position: absolute;
            bottom: 0;
            left:0;
            right: 0;
            padding: 10px 20px;
            text-align: right;
            border-top: solid 1px #ccc;
            background-color: #eee;

            button {
                margin-left: 25px;
            }
        }
    }

    &.active {
        .pop-body {
            max-height: 80%;
            opacity: 1;
        }
    }
}
