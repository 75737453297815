.giga-tabs-wrap {
  padding: 0;
  margin: 10px;
  border-radius: 5px;

  .giga-tab-buttons {
    position: relative;
    display: block;
    padding: 0;
    border-bottom: 1px solid #e0e0e0;
    .giga-tab-btn {
      position: relative;
      display: inline-block;
      text-decoration: none;
      padding: 22px;
      text-transform: uppercase;
      font-size: 14px;
      color: #424f5a;
      text-align: center;
      cursor: pointer;

      &:not(.active) {
        &:hover {
          background-color: inherit;
          color: #7c848a;
        }
      }
      &.active {
        outline: none;
      }
    }

    .blue-bar {
      position: absolute;
      z-index: 10;
      bottom: 0;
      height: 3px;
      background: #458cff;
      display: block;
      left: 0;
      transition: left 0.2s ease;
      -webkit-transition: left 0.2s ease;
    }
  }

  .giga-tab-container {
    padding: 10px;
    .giga-tab-view {
      display: none;

      &.active {
        display: block;
      }

      .giga-tab-view-body {
        &.view-body-height {
          overflow: hidden;
          overflow-y: auto;
        }
      }
    }
  }
}

.giga-tabs-wrap {
  .account-overlay {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.494);
    z-index: 999999;
    .account-wrapper {
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 70%;
      box-shadow: -9px 10px 16px -2px rgba(0, 0, 0, 0.09);
      z-index: 9999;
      padding: 40px;
      overflow-y: auto;
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-radius: 4px;
      background: #f6f6f6;
      .account-title {
        width: 100%;
        display: inline-block;
        text-align: center;
        color: #2b2b2b;
        font-weight: 700;
        font-size: 20px;
        align-self: center;
        padding: 20px;
        margin-bottom: 10px;
      }
      .wrapper_account_det {
        width: 24%;
        padding: 15px;
        background: #fff;
        border-radius: 4px;
        box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.034);
        p {
          padding: 4px 0;
          margin: 4px 0;
          font-size: 14px;
          font-weight: 600;
  
          span {
            display: inline-block;
            color: #466580;
            font-weight: 400;
          }
        }
        &:last-child {
          border-right: none;
        }
      }

      .product-content {
        width: 100%;
        .account-content {
          text-align: center;
          padding: 20px 0;
          margin: 0 0 10px 0;
        }
        .plain_table {
          box-shadow: 1px 4px 5px 0px rgba(0, 0, 0, 0.034);
        }
      }

      .account_order_wrapper {
        margin-bottom: 15px;
        border-bottom: 1px solid #e4e4e4;
        padding-bottom: 18px;
      }
      .wrapper_account_det {
        .order-price {
          font-size: 16px;
          color: #a03436;
          font-weight: 600;
          margin-bottom: 14px;
          span {
            font-size: 14px;
          }
        }

      }

      .clear {
        padding-top: 30px;
        border-top: 1px solid #e4e4e4;
      }
      .product-content {
        margin-top: 30px;
        h4 {
          font-size: 20px;
          margin-bottom: 25px;
        }
        h5 {
          font-size: 14px;
          margin: 8px 0 9px;
          color: rgb(160, 52, 54);
        }
      }
      .close-account {
        cursor: pointer;
        float: right;
        font-size: 15px;
        color: #a03436;
        position: absolute;
        top: 30px;
        right: 30px;
      }
    }
  }
}

// form user
.admin-home__main-section {
  .giga-tabs-wrap { 
      background: rgb(233, 232, 232);
      margin-top: 30px;
      .giga-tab-buttons {
        border-bottom: 1px solid #8646846b;
        .giga-tab-btn {
          font-size: 12px;
          padding: 27px 36px;
          color: #000;
          &.active {
            color: #c468c1;
          }
          &:hover {
            color: #c468c1;
          }
          &:not(.active) {
            &:hover {
              color: #c468c1;
            }
          }
        }
        .blue-bar {
        background-image: linear-gradient(to left, #743ad5, #d53a9d) !important;
        }
  
      }
  
    // .redux-form {
      .radio-group {
        span {
          top: -7px;
        }
      }
      .grid-column {
        background-color: #ffffff9d;
        border-radius: 4px;
        padding: 20px;

        &.grid-column-nopad {
          padding: 0;
        }
      }
      label {
        color: rgba(0, 0, 0, 0.774);
      }
      .form-field-error {
        margin: 10px 0 0 0;
        color: #e73437;
        font-style: unset;
        &:before {
          color: #e73437;
        }
      }
      .separate-right {background-color: transparent;}
      .form-group {
        span {
          label {
            color: rgb(77, 75, 77);
          }
        }
        margin: 15px auto 30px auto;
        textarea.large {
          width: 100% !important;
          max-width: 100%;
          display: block;

        }
        .image-placeholder {
          border-radius: 50%;
          height: 120px !important;
          width: 120px !important;
          margin: 0;
        }
        input[type=file] {
          border: none;
          padding: 0 0;
          &::-webkit-file-upload-button {
            color: rgb(255, 255, 255);
            padding: 1em;
            border: none;
            outline: none;
            background-image: linear-gradient(to left, #743ad5, #d53a9d);
            border-radius: 3px;
          }
        }
        label {
          border: none;
          width: auto;
          display: block;
        }
        input, select {
          border-radius: 3px;
          padding: 10px 10px;
          display: inline-block;
          width: 100%;
          &:focus {
            border-color: #a774a5d3;
          }
        }
        input[name=newsletter] {
          display: inline-block;
          position: relative;
          top: -5px;
          width: auto;
        }
        .field-add {
          display: inline-block;
          margin-left: 20px;
          padding: 10px 10px;
          border-radius: 3px;
          border: solid 1px #ccc;
          font-size: 12px;
          line-height: 13px;
          cursor: pointer;
          box-shadow: 0px 0px 3px #a2a2a2;
        }
        #profile_public-0, #profile_public-1 {
          display: inline-block;
          width: auto;
        }
        .placeholder-wrapper {
          width: 25%;
        }
        &.file-wrapper {
          display: flex;
          flex-wrap: wrap;
          justify-content: center;
          align-items: center;
        }
        .placeholder-content {
          width: 75%;
        }
        select  {
          width: 50%;
        }
      }
    // }
  }
#tinyMceEditor {
  margin-top: 40px;
  }
}


.mobile-app {
  .giga-tabs-wrap {
    .account-overlay {
      .account-wrapper {
        padding: 0;
        width: 100%;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0;
        transform: unset;
        border-radius: 0;
        height: auto;
        .wrapper_account_det {
          width: 100%;
          margin-bottom: 10px;
          box-shadow: unset;
          margin: 5px 10px;
        }
        .product-content {
          margin-top: 10px;
        }
        .account-title {
          font-size: 18px;
          padding-top: 30px;
        }
        p {
          padding: 6px 0;
        }
        .close-account {
          top: 33px;
          position: fixed;
        }
      }
    }
  }
}

@media screen and (max-width: 1600px) {
  .admin-home__main-section {
    .giga-tabs-wrap {
        .form-group {
          &.file-wrapper {
            justify-content: space-between;
            display: flex;;
            .placeholder-wrapper {
              width: 30%;
              .image-placeholder {
                height: 100px !important;
                width: 100px !important;
              }
            }
            .placeholder-content {
              width: 65%;
            }
    
          }
        }


      .account-overlay {
        .account-wrapper {
          width: 80%;
          padding: 30px;
          height: 95%;
        }
      }
    }
  }
}

@media screen and (max-width: 1260px) {
  .admin-home__main-section {
    .giga-tabs-wrap {
        .form-group {
          &.file-wrapper {
            .placeholder-wrapper {
              width: 100%;
            }
            .placeholder-content {
              width: 100%;
              margin-top: 30px;
            }
          }
        }
    }
  }
}
