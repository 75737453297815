.plain_table {
    position: relative;
    .pt_table {
        width: 100%;
        font-size: 13px;
        padding: 0px 10px 5px 10px;
        overflow: auto;
        background-color: white;
        border-bottom: 1px solid #8080804d;
    }
    table {
        width: 100%; 
        border-collapse: collapse;    
        table-layout:fixed;    
    }

    th {
        white-space: nowrap;
        position: sticky;
        background-color: white;
        top: 0;
        text-align: left;
        height: fit-content;
        span{
            border-bottom: 1px solid #8080804d;
            display: inline-block;
            padding: 15px 5px;
            width: 100%;
            margin-left: -10px;
            padding-left: 15px;
        }
    }

    thead tr th:last-of-type span {
        width: calc(100% + 20px);
    }

    td {
        border-bottom: 1px solid #8080804d;
        word-wrap: break-word;

        img {
            max-width: 90%;
            display: block;
            margin: auto;
        }
    }
   
    .pt_footer {
        border-bottom: 1px solid #8080804d;
        padding: 10px;
        min-height: 40px;
        background-color: white;
    }

    .pt_page_change {
        width: fit-content;
        border: 1px solid #8080804d;
        border-radius: 4px;
        i{
            padding: 10px;
            cursor:pointer;
        }
        .fa-angle-right,.fa-angle-left{ 
            &.disabled{
            color: grey;
            cursor:auto;
            }
        }
        input {
            width: 40px;
            text-align: center;
            border-radius: 3px;
           
        }
    }
   
    .content_td {
        padding:1px;
    }

    .content_td span{
        width: 100%;
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        display: inline-block;
        padding: 5px 10px 5px 10px;
        margin-bottom: -3px; 
        line-height: 15px;
        
    }
  
    .popup_content{
        position: absolute;
        font-size: 13px;  
        padding: 10px;
        background-color:white; 
        height: fit-content;
        z-index: 9999999999999999999999999999;
        border: 1px solid grey;
        border-radius: 2px;
        >div{
            position: relative;
            max-width:600px;
            max-height: 300px; 
            overflow-y: auto;
            border-radius: 5px;
            >*{
                display: inline-block;
                vertical-align: top;
            }
        }

    }
    .copy_btn{ 
        float: left;
        width:25px;
        margin:0px 15px 5px 0px;
        cursor: pointer;
    }

    .pt_footer {
        position: relative;
    }
  
    .gtf-section {
        position: absolute;
        right:10px;
        top:13px;

        input {
            border: solid 1px #ccc;
            border-radius: 3px;
            margin-right: 10px;
            padding: 3px 5px;
        }
    }

    .plain-busy {
        position: absolute;
        top:0px;
        left:0px;
        right:0px;
        bottom: 0px;
        z-index: 20;
        background-color: rgba(255,255,255,0.5);

        &:after {
            content: "Loading...";
            display: block;
            position: absolute;
            padding:10px 60px;
            color: #000;
            left: 50%;
            top:50%;
            transform: translate(-50%,-50%);
            background-color: #fff;
            border: solid 1px #ccc;
        }
    }

    .pt_center {
        display: block;
        position: absolute;
        left:50%;
        top:20px;
        white-space: nowrap;
        font-size: 12px;
        span {
            display: inline-block;
            margin: 0 10px;
        }
    }
   
}


