@import './assets/css/tailwind.min.css';
:root {
  --green: #57b846;
  --red: rgb(250, 66, 81);
  --mutedGreen: #50a840;
  --mutedRed: rgb(221, 88, 88);
  --purplyGray: #333;
  --lightGray: #d9d9d9;
  --textGray: rgba(29, 29, 29, 0.7);
  --headerHeight: 50px;
  --adminMenuWidth: 200px;
  --adminMenuHeight: 100px;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
html,
body {
  width: 100%;
  height: 100%;
}
body {
  padding-top: 50px;
}
a {
  text-decoration: none;
  color: #000;
}
li {
  list-style-type: none;
}
body {
  font-family: sans-serif;
  font-family: 'Rubik';
  color: #2f2f2f;
  background-color: #f9f9f9;
}
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

.noselect {
  -webkit-touch-callout: none; /* iOS Safari */
    -webkit-user-select: none; /* Safari */
     -khtml-user-select: none; /* Konqueror HTML */
       -moz-user-select: none; /* Old versions of Firefox */
        -ms-user-select: none; /* Internet Explorer/Edge */
            user-select: none; 
}

.wraptext {
  overflow-wrap: break-word;
}

/* ------------------------------------------------------------------------ */

.container {
  margin-top: 100px;
  max-width: 1800px;
  width: 100%;
  height: 100%;
}

input,
button,
textarea {
  outline: none;
  border: none;
}

.error {
  height: 30px;
  color: var(--mutedRed);
  font-style: italic;
}

.icon {
  cursor: pointer;
}
.black-white-button {
}
.black-white-button {
  background-color: white;
  border: 1.5px solid black;
  cursor: pointer;
  width: 150px;
  height: 50px;

  font-weight: 300;

  text-transform: uppercase;
  letter-spacing: 0.1em;
}
.black-white-button:hover {
  color: white;
  background-color: black;
}
/* --------------------------------------------------------------------- */


.component-selected:after {
  content: " ";
  border: 1px dashed #2680eb;
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  pointer-events: none;
  display: block;
}

.bg-primary{
  background-color: #2680eb;
}
.bg-primary img{
    width: 15px;
    height: 15px;
}

.viewport {
  pointer-events: none;
}
.viewport .header {
  top: -100%;
}
.viewport .toolbox {
  left: -100%;
  position: relative;
}
.viewport .sidebar {
  right: -100%;
  position: relative;
}
.viewport .craftjs-renderer {
  opacity: 0;
}

.viewport.mouse-enabled {
  pointer-events: all;
}
.viewport.loaded .header {
  top: 0;
}
.viewport.loaded .toolbox {
  left: 0;
}
.viewport.loaded .sidebar {
  right: 0px;
}
.side-width-content{
  width: calc(100% - 120px);
}
.viewport.loaded .craftjs-renderer {
  opacity: 1;
  transition-delay: 0.5s;
}
.content-wrap{
  display: block;
  margin: auto;
  max-width: 1440px;
}

.bg-black {
  background-color: #000
}

.bg-white {
  background-color: #fff
}

.bg-green-400 {
  background-color: #2d9d78
}

.bg-primary {
  background-color: #2680eb
}

.bg-light-gray-1 {
  background-color: #4b4b4b
}

.bg-renderer-gray {
  background-color: #e0e0e0
}