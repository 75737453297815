.table-wrapper {
  margin: 0px 10px;
  overflow-x: auto;
}

.table {
  border-radius: 10px;
  font-size: 0.95em;
  width: 100%;
}

.table__head {
  /* color: var(--red); */
  color: var(--green);
  font-size: 1.2em;
}

.table__row {
  height: 50px;
  width: 80%;
  text-align: left;
}

.table__row td,
.table__row th {
  padding: 0 5px;
  height: 70px;
}
.table__row td:first-child,
.table__row th:first-child,
.table__row td:last-child,
.table__row th:last-child {
  padding: 0px 25px;
}

.table__row__cell--fixed-width {
  min-width: 150px;
}
.table__row__cell--icon {
  transition: all ease-in-out 0.3s;
  cursor: pointer;
}
.table__row__cell--icon:hover {
  color: var(--red);
}

.editable-cell__input-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 70%;
}
.editable-cell__input {
  max-width: 120px;
  height: 80%;
  border: 0.5px solid var(--lightGray);
  background: transparent;
  padding: 5px;
  display: block;
}
.editable-cell__input:focus {
  border: 0.5px solid var(--mutedRed);
}

@media only screen and (max-width: 870px) {
  .table-wrapper {
    overflow-x: scroll;
  }
}

@media only screen and (max-width: 800px) {
  .table__row td:first-child,
  .table__row th:first-child,
  .table__row td:last-child,
  .table__row th:last-child {
    padding: 0px 15px;
  }
}
@media only screen and (max-width: 550px) {
  .table {
    font-size: 0.85em;
  }
  .table__row__cell--fixed-width {
    min-width: 100px;
  }
  .editable-cell__input {
    max-width: 75px;
  }
}
