
/* Timer Plugin */
.timer-info {
    background-color: #fff;
    font-size: 17px;
    padding: 20px;
    text-align: center;

    .timer-end {
        background-color: rgb(240, 71, 71);
        color: #fff;
        margin: 20px 0;
        font-size: 16px;
    }

    .timer-start {
        background-color: rgb(240, 186, 71);
        color: #000;
        margin: 20px 0;
        font-size: 16px;
    }
}

.mce-plugin-timer {
    text-align: center;

    h5 {
        font-size: 22px;
        padding: 10px 0 30px 0;
        margin:0;
    }

    em {
        display: inline-block;
        font-size: 15px;
        font-style: normal;
        width: 95px;
        text-align: center;

        b,
        strong {
            display: block;
            padding-top: 5px;
            font-size: 24px;
        }
    }
}