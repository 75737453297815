//// Points.js
.points_page_wrapper {
    .plain_table_title {
        background-color:#f9f9f9;
        margin:30px 0px 10px 0px;
        max-width:350px;
        padding-right:100px;
        border-bottom: solid 1px #b6b6b6;
    }
 
}
//////////////////////////////