.clear:after {
    content: "";
    display: block;
    clear: both;
}

.redux-form {

    padding: 10px;
    position: relative;

    .form-mask {
        display: block;
        position: absolute;
        top:0;
        left:0;
        right:0;
        bottom:0;
        background-color: rgba(255,255,255,0.6);
    }

    input,
    select,
    textarea {
        border: solid 1px #ccc;
        font-size: 13px;
        padding: 5px;
        border-radius: 3px;

        &.small {
            width: 100px !important;
        }

        &.medium {
            width: 200px !important;
        }

        &.large {
            width: 400px !important;
            max-width: calc(100% - 140px);
        }

        &.wide {
            width: calc(100% - 140px);
        }
    }

    input[type=checkbox],
    input[type=radio]{
        width: auto;
    }

    input:read-only {
        color: #5050b7;
        cursor: default;
    }

    select {
        background-color: #fff;
    }

    label {
        position: relative;
        display: inline-block;
        width: 130px;
        font-size: 13px;
        color: #676767;
        top: -7px;
        margin-right: 10px;
        border-bottom: solid 1px #ccc;
    }

    button {
        position: relative;
        display: inline-block;
        padding: 4px;
        border: solid 1px #ccc;
        cursor: pointer;
        padding: 5px 15px;
    }

    .form-buttons {
        margin-top: 40px;
        border-top: solid 1px #ccc;
        padding-top: 25px;
    }

    .ck-editor__editable_inline {
        min-height: 500px;
    }

    .form-group {
        margin-bottom: 15px;
        position: relative;

        .image-placeholder {
            position: relative;
            border: solid 1px #ccc;
            background-color: #eee;
            text-align: center;
            margin-bottom: 20px;
            padding:10px;

            img {
                display: inline-block;
                width: 100%;
                height: 100%;
            }

            .remove-image-placeholder {
                position: absolute;
                right:-20px;
                top:-4px;
                cursor: pointer;

                &:hover {
                    color: red;
                }
            }
        }
        .user-images{
            .image-placeholder{
                display: inline-block;
                margin:5px 30px 5px 5px;
                
            }
        }
  

        .icon-password {
            position: absolute;
            top: 50%;
            right: 12px;
            width: 22px;
            height: 22px;
            margin-top:2px;
            margin-right: -3px;
            transform: translateY(-50%);
            text-align: center;
            cursor: pointer;
        }

        input.password-inp {
            border-right: solid 40px #e6e0e0 !important;
        }
    }


    .radio-group {
        

        span {
            display: inline-block;
            position: relative;
            margin-right: 20px;
            cursor: pointer;

            label {
                width:auto;
                margin-left: 10px;
                color: #5050b7;
                top: 0px;
                margin-right: 0px;
                border-bottom: none;
            }

            * {
                cursor: pointer;
            }

            &:hover {
                color: rgb(124, 12, 12);
            }

            input {
                top: 1px;
                position: relative;
            }
        }
    }

    .form-field-error:before {
        content: "\f071";
        color: rgb(209, 46, 46);
    }

    .form-field-warning:before {
        content: "\f12a";
        color: rgb(255, 174, 0);
    }

    .form-field-error,
    .form-field-warning {
        display: block;
        position: relative;
        margin-left: 140px;
        font-size: 13px;
        margin-top: 5px;
        padding-left: 15px;
        font-style: italic;

        &:before {
            font-family: FontAwesome;
            position: relative;
            font-style: normal;
            left: -10px;
        }
    }

    .relative{
        position: relative;
    }
    .separate-right {
        position: absolute;
        top:0px;
        bottom: 0px;
        right:-15px;
        width: 1px;
        background-color: #ddd;
    }

    .page-bottom-bar {
        display: block;
        position: fixed;
        bottom: 0px;
        left: 120px;
        right: 0;
        background-color: #eee;
        padding: 5px 20px;
        text-align: right;
        border-top: solid 1px #dcdcdc;
        z-index: 50;
    }
    
    .save-changes-form-btn{
        display: inline-block;
        position: relative;
        width: 110px;
        border-radius: 3px;
        z-index: 17;
        text-decoration: none;
        padding: 5px 10px;
        text-transform: uppercase;
        font-size: 13px;
        color: #424f5a;
        text-align: center;
        background-color: #fff;
        border: solid 1px #ccc;
        margin-left: 20px;

        &.danger-btn {
            background-color: rgb(124, 12, 12);
            color: #fff;
            border-color: rgb(83, 4, 4);
        }

        &:hover {
            background-color: #833acd;
            color: #fff;
        }

        &:active {
            background-color: #c93aa3;
            color: #fff;
        }

        &.busy {
            background-color: transparent;
        }

        &:disabled {
            pointer-events: none;
            opacity: .5;
            cursor: default;
        }
    }

    input[name=start_date],input[name=end_date]{
        color:#000;
    }
}


.form-group .sortable-gallery {

    max-height: 300px;
    overflow-y: auto;
    padding: 5px;
    border: solid 1px #ccc;
    margin-bottom: 20px;

    ul {
        margin: 0;
        padding: 0;
        position: relative;

        &:after {
            content: "";
            display: block;
            clear: both;
        }
    }
}

.full-page-loader{
    position: fixed;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0;
    background: rgba(51,51,51,0.7);
    z-index: 999999;
    .lds-spinner{
        position: fixed;
        top: 50%;
        left: 50%;
    }
}

.sortable-item {
    width: 95px;
    height: 95px;
    float: left;
    padding: 10px;
    box-shadow: 0px 0px 5px 0px #ababab;
    display: block !important;
    overflow: hidden;
    margin: 8px;
    border: solid 1px transparent;
    background-color: #fff;

    &.active {
        border: solid 1px #000;
    }

    > div {
        display: block !important;
        width: 100%;
        height: 100%;
        margin: 0 !important;
        position: relative;
        
    }

    img {
        max-width: 100%;
        max-height: 100%;
    }
}

.padd-field {
    .col {
        min-height: 20px;
        text-align: center;
        input {
            border: solid 1px #ccc;
            border-radius: 3px;
            width: 100%;
            color:#000;
            text-align: center;
        }

        span {
            font-size: 12px;
            color: #666;
            display: block;
        }
    }
}
 
@media screen and (max-width: 1300px) {
   
    .redux-form {

        .save-changes-form-btn{
            width: 158px;
            bottom: 25px;
            right: 29px;
        }

        input,
        select,
        textarea {    
            &.large {
                width: 100%;
                max-width: 100%;
            }
    
            &.wide {
                width: 100%;
                max-width: 100%;
            }
        }
    }
}

.col-widget {
    box-shadow: 0px 1px 5px 2px #ccc;
    padding: 20px 10px 10px;
    background-color: #f9f9f9;
    margin-right: 10px;
    width: calc(33.333333% - 10px) !important;
}

.sticker-preview {
    padding: 20px;
    min-height: 400px;
}

.sticker-preview-item {

    background-color: #fff;
    padding: 10px 20px;

    h5 {
        font-size: 13px;
        margin-top:10px;
        margin-bottom: 10px;
    }

    b {
        font-size: 13px;
        font-weight: 600;
    }

    ul {
        padding-left: 30px;
        border-left: solid 1px #333;

        li {
            padding: 2px 0;

            &.prev-brand {
                color: #e43434;
            }

            &.prev-spec {
                color: #2f66b9;
            }
        }
    }

    .prev-item {
        font-size: 13px;
        padding: 10px;
        margin-bottom: 5px;
        background-color: #eee;

        span {
            display: inline-block;
            width:150px;
        }
    }
}

.redux-form button.setup-btn,
.setup-btn {
    background-color: rgb(47, 64, 223);
    color: #fff;
    border: none;
    cursor: pointer;
    border-radius: 3px;
    outline: none;
    padding: 10px 35px;
    margin-right: 40px;

    &:hover {
        background-color: #2634b9;
    }

    &:active {
        background-color: #005bff;
    }
}

.btn {
    cursor: pointer !important;
    border: solid 1px #ccc;
    margin: 10px;
    padding: 5px 20px;
    font-size: 13px;
    border-radius: 3px;
    outline: none;
    box-shadow: none;

    &:hover {
        border-color: #000;
    }

    &:focus,
    &:active {
        border-color: red !important;
        outline: none;
        box-shadow: none;
    }
}

.swal2-container {
    z-index: 9999999999999999999999999999999999999999999999999999999999999;
}