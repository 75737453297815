* {
  height: auto;
}
.admin-home {
  width: 100%;
  height: 100%;
  max-width: 1800px;
  display: flex;
}

.admin-home__main-section {
  margin-left: 120px;
  padding-bottom: 50px;

  &.with--sidebar-opt--fixed {
    margin-left: 180px;
  }

  h1 {
    font-weight: 400;
    margin: 10px;
    font-size: 20px;
    border-bottom: solid 1px rgb(182, 182, 182);
  }
}

.page-message {
  display: block;
  margin: 10px 0;
  background-color: #eee;
  color: #000;
  padding: 10px;
  font-weight: 400;
  font-size: 14px;
  font-family: Arial, Helvetica, sans-serif;

  &.error-message {
    background-color: rgb(253, 193, 169);
    color: #000;
  }
}

#form-search {
  display: block;

  span.search-group {
    display: inline-block;
    padding: 10px;

    label {
      font-size: 13px;
      display: inline-block;
      margin-right: 10px;
    }

    input {
      border: solid 1px #ccc;
      background-color: #fff;
      padding: 10px;
      min-width: 250px;
      font-size: 13px;
    }

    button {
      padding: 10px 20px;
      border-radius: 4px;
      border: solid 1px #ccc;
      background-color: #fff;
      cursor: pointer;

      &:hover {
        border-color: #000;
      }

      &:active {
        border-color: red;
      }

      &.busy {
        cursor: default;
        opacity: 0.3;
        pointer-events: none;
      }
    }
  }
}

 /* SIDEMENU */

.side-menu {
  display: block;
  position: fixed;
  left: 0;
  top:51px;
  bottom:0px;
  width: 120px;
  background-color: #151515;
  border-right: solid 1px #272727;
  z-index: 8000;
  color: #ccc;
  font-size: 14px;
  padding: 10px;

  .item-holder {
    padding: 0px 0px 0px 0px;
    border: 3px solid transparent;
    position: relative;
    &:after {
      content: '';
      position: absolute;
      top: -3px;
      right: 0;
      transform: translateX(50%);
      width: 40px;
      height: 110%;
      background-color: transparent;
    }
    span {
      display: block;
      color: rgb(187, 183, 183);
    }
    

    a {
      color: #727272;
      display: block;
      padding: 5px 10px;

      i {
        display: inline-block;
        font-size: 20px;
        margin-bottom: 10px;
        position: relative;
        top:3px;
        color: #fff;
      }

      &:hover {
        color:#fb60e0;
      }
    }

    >a {
      padding: 10px;
      text-align: center;
      font-size: 12px;
    }

    &.active {
      border:3px solid #7e3ace;
      border-radius: 4px;
    }
    .child-hold {
      padding: 10px 35px 10px 35px;
      display: none;
      position: relative; 
      box-shadow: 0 1px 4px 1px  rgba(0, 0, 0, 0.178);

      > div {
        padding-left: 15px;
        >div {
          padding-left: 30px;
        }
      }

      i {
        font-size: 14px;
        color: #a774a5;
        margin: 0;
        top:1px;
      }

      .child-hold {
        
        i {
          font-size: 10px;
          top:0px;
        }
      }

       a {
         color:#fff;
         display: block;
         padding: 8px 5px;
         font-size: 13px;
         display: flex;
         white-space: nowrap;
         font-weight: 400;
         i {
          margin-right: 12px;
          display: inline-block;
          display: none;
         }

         &:hover {
          color:#fb60e0;
         
         }
         &.active {
           color: #fb60e0 !important;
         }
       }

       ul {
         display: block;
       }

       &.active {
         border: 3px solid #7e3ace;
         border-radius: 4px;
         background-color: #292933;
         box-shadow: 4px 4px 9px 0px #0c0c0c;
         z-index: 9;

         a {
           display: block;
         }

         &:before {
           content: '';
           position: absolute;
           left: -13px;
           display: block;
           border-top: 12px solid transparent;
           border-bottom: 12px solid transparent;
           border-right: 12px solid #8050d0;
           top: 5%;
           width: 0;
           height: 0;

          }
       }
    }

    &:hover {  
      .child-hold {
        background-color: #1d222b;
        &.active {
          display: block;
          position: absolute;
          right: 0;
          transform: translateX(104%);
          flex-wrap: nowrap;
          top: -3px;
          &:after {
            content: '';
            position: absolute;
            top: 0;
            right: 0;
            transform: translateX(50%);
            width: 20px;
            height: 100%;
            background-color: transparent;
          }
        }
      }
    }

    li.active > .child-hold { display: block;}

    
  }
}



/* SCROLLBAR **/
/* Gradient Scrollbar */
// ::-webkit-scrollbar {
//   width: 8px;
// }

// ::-webkit-scrollbar-track {
//   background: #000; /* opacity: 0; should do the thing either */
// }

// ::-webkit-scrollbar-thumb {
  
//   box-shadow: 0px 0px 0px 100000vh black;
//   border-radius: 5px;
//   width:8px;
//   background:  linear-gradient(0deg, rgb(165, 12, 170) 0%, rgb(24, 74, 184) 100%);
// }




.main-page-title{
  font-size: 14px;
  color: #4e4e4e;
  font-weight: 500;
  line-height: 26px;
  background: #eeeeee;
  padding-left: 10px;
}
.main-page-title a{
  padding-right: 5px;
  display: inline-block;
}


/* page sidebar nav */
.page-sidebar-opt--fixed {
  display: block;
  position: fixed;
  top:51px;
  bottom:0px;
  left: 115px;
  width:60px;
  background-color: #d4d4d4;
  color:#716d6d;

  >span {
    display: block;
    text-align: center;
    padding: 20px 0;
    cursor: pointer;
    transition: all .2s ease-in-out;

    &:hover {
      color:#000;
      background-color: #eee;
    }

    &.active {
      cursor: default;
      pointer-events: none;
      background-color: #a774a5;
      color:#fff;
    }
  }
}


.border-gradient {
  border: 3px solid !important;
  border-image-slice: 1 !important;
  border-width: 3px;
}
.border-gradient-purple {
  border-image-source: linear-gradient(to left, #743ad5, #d53a9d) !important;
}

/* Page */ 
.page-details {
  height: 100%;
  padding: 0 0 0 120px;

  h1 {
    font-size: 16px;
    margin:0;
    font-weight: 400;
    padding: 10px;
    background-color: rgb(216, 216, 216);
    height: 39px;
  }

  .page-details-hold {
    position: absolute;
    top: 89px;
    left: 120px;
    right: 0px;
    padding: 10px;
    height: calc(100% - 90px);
    overflow: auto;
  }
}

/* Card */ 
.card {
  display: block;
  position: relative;
  margin: 5px;
  padding: 40px 15px 15px;
  background-color: #fdfdfd;
  // border: solid 1px #e6e6e6;
  box-shadow: 0px 0px 5px 0px #ccc;
  border-radius: 0 0 5px 5px;

  h4 {
    margin: 0 0 15px 0;
    padding: 5px 15px;
    font-size: 14px;
    background-color: #eee;
    position: absolute;
    top: 0px;
    left: -1px;
    right: -1px;
    background: #667282;
    color: #fff;
    font-weight: 400;

    &.alert {
      background-color: #e86969;
    }
  }

  h5 {
    font-size: 13px;
    margin: 20px 0 20px 0;
    padding: 0 0 10px 0;
    border-bottom: solid 1px #ccc;
  }

  .card-field {
    position: relative;
    &:after { content: ""; display: block; clear: both;}

    padding: 0;
    font-size: 13px;
    border-bottom: dotted 1px #e6e6e6;
    font-family: Arial, Helvetica, sans-serif;

    &:last-child {
      border-bottom: none;
    }

    label {
      display: block;
      width: 140px;
      font-size: 12px;
      float: left;
      color: #777;
      border-right: dotted 1px #e6e6e6;
      padding: 10px 0 10px 0;
    }

    span {
      display: block;
      float: left;
      width: calc(100% - 140px);
      color:#000;
      font-weight: 400;
      padding: 10px 0 10px 10px;

      .alert-icon {
        position: absolute;
        right: 5px;
        top: 10px;
        color: red;
      }
    }
  }
}

/* page info desk */ 
.page-infodesk {
  padding: 10px 10px 0 10px;
  font-size: 12px;
  color: #555;

  span {
    border-radius: 20px;
    background-color: #fff;
    border: solid 1px #ccc;
    padding: 4px 10px;
    line-height: 0;
  }
}

/* position */ 
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.field-options {
  display: block;
  position: relative;
  margin-top:-20px;
  clear: both;
  text-align: right;

  .field-opt-item {
    display: inline-block;
    margin: 5px 10px;
    color: #000;
    font-size: 14px;
  }

  a {
    cursor: pointer;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }
}


.row {
  display: block;
  position: relative;

  &:after {
    content: "";
    display: block;
    clear: both;
  }

  .col {
    float: left;
  }

  .col-12 {
    width: 100%;
  }
  .col-6 {
    width: 50%;
  }
  .col-4 {
    width: 33.333333%;
  }
  .col-3 {
    width: 25%;
  }
}