
.giga-tabs {



    .giga-tabs-buttons {
        border-bottom: solid 1px #fff;
        position: relative;
        &:after {
            content: "";
            display: block;
            clear: both;
        }

        span {
            display: block;
            padding: 5px 20px;
            font-size: 18px;
            text-align: center;
            float: left;
            font-weight: 600;
            cursor: pointer;

            &.active-tab {
                background-color: red;
                color: #fff;
            }
        }
    }

    .giga-tabs-content {
        
        .tabcontent {
            display: none;

            &.active-tab {
                display: block;
            }
        }
    }

}