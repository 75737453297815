.morph-table {
    width: 100%;
    border-collapse: collapse;
    border: solid 1px #ccc;

    th {
        padding: 5px;
        font-size: 14px;
        text-align: left;
        font-weight: 600;
        border-right: solid 1px #ccc;
        border-bottom: solid 1px #ccc;

        &:last-child {
            border-right: none;
        }
    }

    td {
        padding: 5px;
        font-size: 14px;
        font-weight: 400;

        border-right: solid 1px #ccc;
        border-bottom: solid 1px #ccc;

        &:last-child {
            border-right: none;
        }
    }

    tr:last-child td {
        border-bottom: none;
    }
}