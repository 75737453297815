.product-editable {
  position: relative;
  overflow: hidden;
}
.edit-product {
  width: 100%;
  height: 100%;
  bottom: -250px;
  left: 0;
  position: absolute;
  transition: all ease-in-out 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
}
.product-editable:hover .edit-product {
  background: rgba(226, 224, 224, 0.31);
  transform: translateY(-250px);
}
.edit-product__icon {
  font-size: 2.4em;
}
.edit-product__icon:hover {
  /* color: var(--red); */
  color: var(--green);
}
.edit--button {
  background-color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 150px;
  height: 60px;
  margin-top: 20px;
  font-size: 16px;
  font-weight: 200;
  border: 1.5px solid black;
  cursor: pointer;
}
.edit--button:hover {
  color: white;
  background-color: black;
}
