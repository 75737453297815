.product-list-wrapper {
    height: fit-content;
    position: relative;

    .plw-columns {
        width: 100px;
    }

    .form-group {
        display: inline-block;
        // margin-right: 15px;
    }
}
.showMe {
    display: block;
}
.hideMe {
    display: none;
}
.media_query{
    cursor: pointer;
    position: fixed;
    top: 0px;
    right: 115px; 
    margin-top: 5px;
    font-size: 14px;
    font-weight: bold;
    color: #868686;
}
///icons
.custom-icon-wrapper {
    margin: 0px 8px 0px 8px;
    // display: inline-block;
    padding: 9px;
    appearance: none;
    background-color: #fff;
    border: solid 1px #ccc;
    border-radius: 3px;
    box-shadow: none;
    color: grey;
    font-size: 15px;
    cursor: pointer;
    &:focus {
        background-color: #fff;
        border-color: #207ab7;
        box-shadow: none;
        outline: 0;
    }

    img {
        width: 36px;
        margin-bottom: -12px;
    }
}

.add-template-btn {
    padding: 9px;
    .fa-clone {
        font-size: 16px;
    }
}
.preview-btn {
    padding: 8px;
    font-size: 18px;
}

//////item_types_wrapper
 

//breadcrumb nav
.item_types_breadcrumbs{
    margin-bottom: 20px;
    position: fixed;
    z-index: 99999999;
    top: 5px;
    left: 120px;
    p{
        cursor:pointer;
        display: inline-block;
        color: rgba(34, 47, 62, 0.7);
        font-size: 14px;
        // content: "\f101";
    }
 
}
.item_types_breadcrumbs .fa-angle-double-right{
    position: relative;
    // bottom: -1px;
    color: rgba(34, 47, 62, 0.7);
    padding: 0px 5px;
}
.active_nav_prev{
    font-weight: bold;
    i{
        font-weight: bold;
    }
}


.previewContainer {
    width: fit-content;
    display: inline-block;
    position: relative;
    padding: 10px 20px;
}
.choose_layout_wrapper .clw_warrning{  
    padding:10px;
    color: rgba(34, 47, 62, 0.7);
    font-size: 14px;
} 
.btns_layout_list{
    width: fit-content;
    cursor: pointer;
    position: absolute;
    z-index: 999999999999999;
    background-color: white;
    right:20px;
    width: 25px;
  
    height: 25px;
    border:1px solid rgb(209, 212, 215);
    color: rgba(34, 47, 62, 0.318);
  
}
.add_layout_btn{
    top: 10px;
    .fa{
        padding: 3px 0px 0px 3px;
    }
}
.remove_layout_btn{
    // border-bottom-left-radius: 3px;
    top: 34px;
    .fa{
        padding: 2px 0px 0px 5px;
    }
}

.edit_layout_inner_wrapper{
    position: relative;
    z-index: 1;
    margin-top:50px;
    padding: 0px 50px 0px 30px;
    // min-width:500px;
    max-width:fit-content;
}

.edit_layout_inner_wrapper >div{
    display: inline-block;
    vertical-align:top; 
}



// type == "edit_layout"

.product_editor{
    margin-left:50px;
}
.product_editor p{
    color: rgba(34, 47, 62, 0.7);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.3;
    padding: 0 8px 0 0;
    text-transform: none;
}
.product_editor_options{
    margin-top: 10px;
}
.product_editor button{
    margin-right: 10px ;
    margin-bottom: 10px; 
    cursor: pointer;
}

.edit_type{
    overflow: hidden;
    padding: 20px 20px 20px 20px;
}

.edit_layout_wrapper .editable{
    position: relative;
    cursor: pointer;
    overflow: visible!important;
}

.edit_layout_wrapper .overlay{ 
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0; 
    z-index: 999999999999;
    &:active{
        pointer-events: none;
    }
    &:hover,&.show_my_overlay{
        visibility: visible; 
        span{
            position: absolute;
        }
        span:nth-child(1),span:nth-child(3){
            left: -600px;
            right: -600px; 
            height:2px;
        }
        span:nth-child(2),span:nth-child(4){
            top: -600px;
            bottom: -600px; 
            width:2px;
        }
        span:nth-child(1){
            top:0px;
        }
        span:nth-child(3){
            bottom:0px;
        }
        span:nth-child(2){
            left:0px;
        }
        span:nth-child(4){
            right:0px;
        }
    }
    &:hover{
        span:nth-child(1),span:nth-child(3){ 
            border:1px dashed #759eda;
        }
        span:nth-child(2),span:nth-child(4){ 
            border:1px dashed #759eda;
        }
    }
    &.show_my_overlay{
        background:  rgba(0, 123, 237, 0.3);
        span:nth-child(1),span:nth-child(3){ 
            border:1px dashed #104493; 
        }
        span:nth-child(2),span:nth-child(4){ 
            border:1px dashed #104493;
            
        }
        
        
    }

}
 
.overlay_padding,.overlay_margin{  
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    z-index: 9; 
    pointer-events:none;
    display: none;
    border-color:rgb(194,221,182);
    border-style:solid; 
    &.show_me{
        display: block;
    }
}



.main_item_wrapper >.overlay{ 
    z-index: 0; 
}

 


 
.item_tree_wrapper{ 
    // width: 320px;
    //temp for testing;
    // margin-right: 240px;
    border-bottom: 1px solid #e4e4e4;
    // position: absolute;
    // right: 0px;
    background: white;
    li{
        cursor: pointer;
    }
    //
    p{
        display: inline-block;
    }
    .fa-times{ 
        right: 0px; 
        padding: 5px 7px;
        margin-top:-5px;
        margin-right: -5px; 
    }
    .fa-plus{
        margin-right: 5px;
    }
    .fa-arrow-circle-o-down,.fa-arrow-circle-o-right{
        font-size: 17px; 
        margin: -2px 5px 3px 0;
    } 
    .fa-lock,.fa-unlock{ 
        font-size: 14px;
        margin-right: 10px;
    }
    .fa-unlock{
        margin-right: 7px;
    }
    .fa-link{
        width: 13px;
    }
    .fa-unlink{
        width: 13px;
    }
    .link_disabled{
        color: rgb(238, 238, 238);
    }
    .fa-lock,.fa-unlock,.fa-arrow-circle-o-down,.fa-arrow-circle-o-right,.fa-link,.fa-unlink{
        cursor: pointer;
        &::after{ 
            position: absolute;
            height: fit-content;
            width:fit-content;
            white-space: nowrap;
            overflow-x: hidden;
            background: white;
            border: 1px solid #eee;
            padding: 6px 5px  6px 5px;
            right: 80px;
            top:-2px;
            text-align: left;
            font-weight: normal;
            font-size: 12px;
            color: grey;
            font-family: "Liberation Sans";
        }

    }
  
    .fa-arrow-circle-o-down:hover::after,
    .fa-arrow-circle-o-right:hover::after{
        content:"Change wrapping direcition";
    }
    
    .fa-unlock:hover::after{
        content:"Change to allow elements to wrap";
    }
    
    .fa-lock:hover::after{
        content:"Change to block elements from wrapping";
    }


    // .fa-link:hover::after{
    //     content:"Add link";
    // }
    // .fa-unlink:hover::after{
    //     content:"Remove link";
    // }

    .fa-times,.fa-undo,.fa-lock,.fa-unlock,.fa-arrow-circle-o-down,.fa-arrow-circle-o-right,.fa-link,.fa-unlink{
        float:right;
    }
    input{
        width: 60px;
        float: right;
        margin-bottom: -29px; 
        border: 1px solid rgb(237, 238, 240);
        /* border-radius: 5px; */
        border-top: 0px;
        text-indent: 5px;
    }
    .form-group{
        float: right;
        padding: 0px !important;
    }
  
}

.item_tree_wrapper.css_editor{
    position:fixed;
    bottom:141px;
    height:205px;
    
    
    .item_tree_title{
        display: block;
        font-weight: 900;
        width: fit-content;
        transition: 0.5s;
        cursor: pointer;
        // border-right:1px solid rgb(237, 238, 240);
        &.active{
            color: #333;
            border-bottom:1px solid  rgba(112, 38, 38, 0.707);
            width: 100px;
            border-right: 0px;
        }
    }
    .item_tree_main_css_content{
        position: relative;
        height: 173px; 
        display: flex;

    }
    .item_tree_titles{
        width: fit-content;
        padding-right: 30px;
        border-right: 1px solid rgb(211, 211, 211);
        margin-right: 20px;
        
    }
 
    .item_tree_params{
        
        display: flex;
        overflow-x: auto;
        .box{ 
            padding-right: 20px;
            width: 280px;
        }  
        .item_tree_title{
            display: flex;
            position: relative;
            width: 100%;
            >p{
                width: 100px;
            }
            .color_field_side_nav {
                height: 15px; 
            }
            .select_field_side_nav select{
                height: 26px;
                border:0px;
                outline:0px; 
                background: none; 
                margin-top: -3px;
                position: absolute;
                top: 2px;

         
            }
        }

    }
    input,select{
        width: 150px;
    }
    select{
        background: none;

    }
}

.fa-times.empty_list{
    color: grey;
    font-size: 12px;
    top: 6px;
    right: 4px;
    position: absolute;
}
 
.item_tree_main_title{ 
    width: 100%;
    display: inline-block;
    color: #333;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 10px;
    background-color: #e4e4e4;
    .fa{
        float:right;
        color: rgba(34, 47, 62, 0.7);
    }
    // .fa-refresh{ 
    //     margin-right: 17px;
    // }
    .fa-info{
        margin-right: 9px;
    }
  
}
span.mq_info{
    position: relative;
    // left: 50px;
    float: right;
    color: rgb(212, 212, 212);
    &:before {
        position: absolute;
        content: "";
        left: 0;
        top: 50%;
        right: 0;
        border-top: 1px solid;
        border-color: inherit;
      
        -webkit-transform:rotate(-40deg);
        -moz-transform:rotate(-40deg);
        -ms-transform:rotate(-40deg);
        -o-transform:rotate(-40deg);
        transform:rotate(-40deg);
    }
}
.item_tree_main_content{
    padding: 10px 10px 30px 10px; 
}
.item_tree_title,.option_list p{
    padding: 5px ;
    // padding-left: 10px;
    color: rgba(34, 47, 62, 0.7);
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    // line-height: 1.3; 
    text-transform: none;
    border-bottom: 1px solid #e4e4e4;
 
} 
.item_tree_title.block{
    color:#d2cccc;
}
.item_tree_content{
    margin-left: 15px;
    position: relative;
 
}

.item_tree_title{
    position: relative;
}
.option_list{
    top:24px;
    position: absolute;
    left: -15px;
    right: 0;
    // left: -40px;
    // right: -10px;
    // top:24px;
    background-color: white;
    padding: 10px 10px 10px 13px;
    border: 1px solid rgb(228, 214, 214);
    // border-left:none;
    // border-right: none;
    z-index: 999999999;
    // box-shadow: 5px 5px 5px grey;
    div *{
        display: inline-block;
        vertical-align: top;
        margin-bottom: 10px;
    }
    p{
        border-bottom: none;
        padding-top:0px;
        padding-bottom: 0px;
      
    }
 
 
}
.devider{
    background-color: #0505052b;
    height: 1px; 
    border: 0;
    margin-bottom: 10px ;
}
.wrapper_options{
    >div{
       float: right;
       margin-right: -9px;
    } 
}
 
.option_list_btns{
    width: 100%;
    margin-top: 10px;
    p{
        border: 1px solid  rgb(228, 214, 214);
        border-radius: 3px;
        padding:5px 10px;
        margin-bottom: 0px;
        margin-right: 0px;
    }
    p:last-child{
        float: right;
    }
}
.active_tree_title{
    // color:#759eda;
    // border-bottom: 1px solid #759eda;
    font-weight: bold;
}

.option_list .sub_info{
    position: relative;
    display: block;
    margin-left: 41px;
    &:before{
        content: "";
        background: rgba(128, 128, 128, 0.842);
        z-index: 9999999999999;
        display: block;
        width: 1px;
        height:calc(100% - 3px);
        position: absolute;
        top:-5px;
        left: 0px;


    }
    p{
        display: block;
        padding-left: 15px;
        position: relative;
        &::before{
            content: "";
            background: grey;
            z-index: 9999999999999;
            display: block;
            width: 10px;
            height: 1px;
            position: absolute;
            top:6px;
            left: 0px;


        }
    }
    // border-left: 1px solid grey;
}




// type == "preview_layout_with_ids"
.preview_wrapper_main{
    width: 100vw;
    margin-left: -20px;  
    padding-bottom: 600px;
}
 
.empty_msg{
    margin: 50px auto;
    width: fit-content;
    color: rgb(196, 148, 148);
}

.table_scroll_side_nav{
    width: 368px;
    right:0px; 
    top: 28px;
    bottom:139px; 
    z-index: 999999999999999999999999; 
    position:fixed;
    overflow-y:auto; 
    border: 1px solid #e4e4e4;
    background-color: white; 
    // padding-bottom: 200px; 
     
    &.change_sidenav_position{ 
        left:0px; 
   
    }
  

    .form-group .textfield{
        font-size: 12px !important;
    }
    .rc-color-picker-wrap {
        margin: -2px 0px 0px 0px;
        transform: scale(0.9); 
        
    }
    .rc-color-picker-trigger {
        border: 1px solid #9999996e;
    }
 
 
}
.preview_wrapper_main{
    .fa-angle-double-right,.fa-angle-double-left,.fa-eye,.fa-eye-slash{
        cursor: pointer;
        position:fixed;
        top: 0px;
        right: 6px;
        font-size: 27px;
        color: #868686;
    
    }
    .fa-eye,.fa-eye-slash{
        top: 4px;
        right: 40px;
        font-size: 20px;
    }
}

.table_scroll_side_nav table{
    table-layout: fixed;
    width: 100%;
    background-color: white;
    border: solid 1px #e4e4e4;
    border-collapse: collapse;
    font-size: 12px;
    text-align: center;
    color: grey;

    border-collapse: collapse;
 
    transition: all 1s ease;
   

    td,th{
        padding:5px;
        border: solid 1px #e4e4e4; 
    }
    th{
        font-size: 12px;
    }
    td{ 
 
        // width:60px;
        input{
            text-align: center;
            color: grey;
            // width: 60px;
            width: 100%;
        }
    }
   
    .form-group{
        padding: 1px !important;
    } 
    .focus_on_active_breaking_point p{
        color:red;
        font-weight: 700;

    }
    .tab_dots{
        width: 20px;
    }
    .tab_dots:before {
        width: 20px;
        content: ' \25CF';
        font-size: 10px;
        color: #92b236;
    }
    .arrow_checker{
        position: relative;
        height: 15px;
        height: 15px;
        
        svg:first-child{
            z-index: 1;
            top: -11px;
            left: 12px;
            position: absolute;
            height: 39px;
            width: 19px;
            color: white;
        }
        svg:last-child{
            top: -11px;
            left: 11px;
            position: absolute;
            height: 39px;
            width: 21px;
            color: #e5dddd;
        }
        &.checked{
            svg:first-child, svg:last-child{
                color: rgba(197, 187, 187, 0.701);
            }

            &:hover{
                cursor:pointer;
                &:before{
                    content:"Remove content for this breaking point" ;  
                    color:grey;
                    position: absolute;
                    height: fit-content;
                    width: 234px;
                    overflow-x: hidden;
                    background: white;
                    border: 1px solid #eee;
                    padding: 7px 0px  6px 15px;
                    left: 44px;
                    top:-7px;
                    text-align: left;
                    font-weight: normal;
                }
            }
        }
        &.biggest_size{
            &:hover{ 
                &:before{
                    content:"You can't remove this content" ; 
                }
            }
        }
    }
  
    .content_mq_checker{
        background-color: white;
        width: 11px;
        height: 11px;
        margin: 0px auto;
        position: relative;
        border: 1px solid rgb(197, 187, 187);
        div{
            width: 7px;
            height: 7px;
            position: absolute;
            top: 1px;
            left: 1px;
            background: white; 
        }
    }
    .content_mq_checker,.content_mq_checker div{
      
        border-radius: 6px;
 
    }
    .content_mq_checker.checked{
        border: 1px solid #207ab7;
        border: 1px solid  rgb(179, 172, 172);
        cursor: pointer;
        div{
            border: 1px solid #207ab7;
            background: #207ab7; 
            border: 1px solid rgb(179, 172, 172);
            background:  rgb(179, 172, 172);
        }
    }
}


// TEMP CSS
//columns_on_diff_screen_sizes
.top_titles{
    th{
        width: 50px;
    }
    td{
        display: flex;
        
    }
}
.item_position_title_img{
    // height: 20px;
    // width: 40px;
    position: relative;
    display: flex;
    flex-wrap: wrap; 
    div{
        
        width: calc(50% - 2px);
       
        height:15px;
        border: 1px solid #eee;
        display: flex;
   
    }
    div:nth-child(1),div:nth-child(2){
        margin-bottom: 2px;
    }
    div:nth-child(1),div:nth-child(3){
        margin-right: 2px;
    }
  
}

.title_horizontal_space_around_items{
    div:nth-child(1)::before,div:nth-child(3)::after{ 
        content:'';
        display:block;
        position: absolute;
        background: #eee;
        width:calc(100% - 3px);
        height:2px; 
    }
    div:nth-child(3)::after{
        bottom: -2px; 
    }
}
.title_vertical_space_around_items{
    div:nth-child(1)::before,div:nth-child(4)::after{ 
        content:'';
        display:block;
        position: absolute;
        background: #eee; 
        height:100%;
        width:2px; 
    }
    div:nth-child(4)::after{
        top:0px;
        right: 0px; 
    }
 
}
.title_horizontal_space_beatween_items{
    div:nth-child(1)::after{ 
        content:'';
        display:block;
        position: absolute;
        background: #eee;
        width:calc(100% - 3px);
        height:2px;  
        top: 47%;
    }
 
}
.title_vertical_space_beatween_items{
    div:nth-child(1)::after{ 
        content:'';
        display:block;
        position: absolute;
        background: #eee; 
        width:2px;  
        height: 100%;
        right: 50%;
    }
}




.items_wraper_and_space_btw_items{
    th{
        text-align: initial;
        width: 100%;
        display: inline-block;
        color: #685c5c;
        font-weight: bold;
        padding: 5px 10px;
        background-color: #e4e4e4;
    }
    
}


.space_btw_items{
    td{ 
        vertical-align :middle;
        width: 50%;
 
        .fa{
            width: 28px;
            display: inline-block; 
            padding-right: 4px;
        }
        .form-group{  
            display: inline-block;
            width: 60px;
      
        }

    }

}

.background_colors{
    width: 240px;
    th{
        width: 250px; 
        text-align: initial;
        width: 100%;
        display: inline-block;
        color: #685c5c;
        /* font-size: 14px; */
        font-weight: bold;
        padding: 5px 10px;
        background-color: #e4e4e4;
   
    }
    tr{
        display: flex;
        flex-wrap: nowrap;
   
    }
    td{
        padding: 0px;
    }
    h3{
        font-size: 12px;
    }
}
.color_picker_td{
    width: 50%;
    display: flex;
    flex-wrap: nowrap;  
    height: 40px;
    padding: 0px !important;
    align-items: center;
    justify-content: space-between;
    h3{
        padding: 0px 0px 0px 5px;
    }
    span{
        
        height: 38px;
        margin-bottom: -3px;
        margin-right: -2px; 
        border: 0px;
        border-left: 1px solid #e4e4e4;
        box-shadow: none;
        // background-image: linear-gradient(45deg, #ffffff00 35.71%, #c9c9c9 35.71%, #c9c9c9 50%, #ffffff00 50%, #ffffff00 85.71%, #c9c9c9 85.71%, #c9c9c9 100%);
        background-image:  linear-gradient(
            45deg
            , #ffffff00 35.71%, #c9c9c940 35.71%, #c9c9c94f 50%, #ffffff00 50%, #ffffff00 85.71%, #c9c9c975 85.71%, #c9c9c987 100%);
        background-size: 9.90px 9.90px;

         
    }
}

.items_wraper_and_space_btw_items{
    th{
        width: 240px; 
    }    
}





.focus_on_active_breaking_point{
    color:red;
    font-weight: bold;

}


//todo



.preview_wrapper_main{
    // overflow: hidden;
    .plw-columns{
        margin-right: 60px;
    }
}
.preview_wrapper_main .product_plugin_wrapper_that_gets_inserted{
    position: relative;
    z-index: 999;
    &.show_my_borders{       
        box-shadow: 0 1px 0 0 #b2d7fa,  0 -1px 0 0 #b2d7fa;
    }
}
.items_wrapper{ 
    position: relative;
    margin: 0px  auto; 
    &.show_my_borders{ 
    box-shadow:  -1px 0 0 #b2d7fa, 1px 0 0 #b2d7fa;
    }

}

.align_col{
    min-height: 25px!important;
    >.final_step_overlay{
        z-index: 9999;
        position: absolute;
        top: 0;
        left: 0;
        bottom: 0;
        right: 0; 
        span{
            position: absolute;
        }
        span:nth-child(1),span:nth-child(3){
            left: -10px;
            right: -10px; 
            height:2px;
            border:1px dashed #b2d7fa;
        }
        span:nth-child(2),span:nth-child(4){
            top: -10px;
            bottom: -10px; 
            width:2px;
            border:1px dashed #b2d7fa;
        }
        span:nth-child(1){
            top:0px;
        }
        span:nth-child(3){
            bottom:0px;
        }
        span:nth-child(2){
            left:0px;
        }
        span:nth-child(4){
            right:0px;
        }
    }
}
.final_step_overlay{
    pointer-events: none;
}
//bolje van itema todo ako se bude menjalo..
.align_col>div{
    position: relative;
}


.step_3_show_overlay_btn{
    // temp
    cursor: pointer;
    padding: 10px 5px!important;
}
/////temp za test da se ne dupira u layout css



///draggable
// .drag_regulation{
//     // background-color: rebeccapurple;
//     // -webkit-transition: width 0.8s ease-in-out;
//     // -moz-transition: width 0.8s ease-in-out;
//     // -o-transition: width 0.8s ease-in-out;
//     // transition: width 0.8s ease-in-out;
//     // width:1px !important;
// }
.dragged_id,.dragged_id_active_tree_title{ 
    opacity:0.5;
}
 
.dragged_id_active_tree_title{
    border: 1px solid lightcoral;
}
.id_under_dragged_id::after{ 
        content: "";
        background: rgba(245, 31, 77, 0.241); 
        z-index: 9999999999999;
        display: block;
        width: 100%;
        height: 100%; 
        top: 0px;
        bottom: 0px;
        left: 0px;
        right: 0px;
        position: absolute;
    
}
.dropzone_active_tree_title[style]{
    pointer-events: all !important;
}
.id_under_dragged_id_active_tree_title > .item_tree_title >p{
    color: red;
}
.dropzone * {
    user-select: none;
    -ms-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -webkit-user-drag: none; 
    pointer-events: none;
 
}

.remove_item_from_list{
    cursor: pointer;
    width: 26px;
    height: 26px;
    pointer-events: initial;
    // background-color: white;
    position: absolute;
    right: 0px;
    top: 0px;
    color:#b2d7fa;
    z-index: 999999999;
    text-align: center;
    .fa{
        margin-top: 3px;
        font-size: 17px;
    }


}

.scrollMeOnDrag{
    position: fixed;
    width: 100%; 
    color: grey; 
    height:55px; 
    z-index: 999999999999999 ;
    text-align: center;
    z-index: 9999999999999999999 ;
    .fa{ 
        font-size: 27px;
    }
     
}
.goingUp{
    top:0px;
    .fa{
        margin-left: -12px;
        -webkit-transform: rotate(90deg);
        -moz-transform: rotate(90deg);
        -ms-transform: rotate(90deg);
        -o-transform: rotate(90deg);
        transform: rotate(90deg)
    }
}
.goingDown{ 
    bottom:0px;
    .fa{  
        margin-left: -14px;
        margin-top: 17px;
        -webkit-transform: rotate(-90deg);
        -moz-transform: rotate(-90deg);
        -ms-transform: rotate(-90deg);
        -o-transform: rotate(-90deg);
        transform: rotate(-90deg)
    }
}


 

.save-items-btn{
    position: fixed;
    left:5px;
    bottom:10px;
    z-index: 9999999999;
    padding: 5px 20px;
    border: none;
    background-color: #8a9fb7;
    color: #fff;
    cursor: pointer;
    margin-left: 20px;
}

// .rc-color-picker-panel-params-lable
// .rc-color-picker-trigger{
//     z-index: 99999999999999999999999999999999999999999999999999999;
// }
// body >div:last-child >div{
//     position: absolute;top: 298px;left: 300px;width: 100%;height: 300px;display: block;background: blue;z-index: 999999999999999999;
// }
body{
    position: relative;
}
// body >div:last-child{
//     z-index: 1000000000000000000000000000000000000000000000;
// }
// .modal-dialog input {
//     position: relative;
//     }
    
//     .modal-dialog .color-picker {
//     position: absolute !important;
//     left: 310px !important;
//     top: 56px !important;
//     }