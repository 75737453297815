
/* TABS PLUGINS */
.mce-plugin-tabs {
    position: relative;
}
.mce-plugins-tabs-buttons {
    position: relative;
    border-bottom: solid 1px #777;
}
.mce-plugins-tabs-buttons:after {
    content: "";
    display: block;
    clear: both;
}
.mce-plugins-tabs-buttons .tab-button {
    appearance: none;
    display: block;
    position: relative;
    padding: 5px 20px;
    font-size: 17px;
    float: left;
    background: #fff;
    color: #000;
    border: solid 1px #777;
    border-bottom: none;
    cursor: pointer;
    outline: none;
}

.mce-plugins-tabs-buttons .tab-button.tab-button-active {
    background-color: royalblue;
    color: #ffffff;
}

.tab-content {
    min-height: 100px;
    display: none;
}

.tab-content.tab-content-active {
    display: block;
}

.mce-plugins-tabs-buttons {
    display: flex;
}

.mce-plugins-tabs-buttons .tab-button strong {
    font-weight: 400;
}

.mce-plugins-tabs-buttons .tab-button.icon-left:after,
.mce-plugins-tabs-buttons .tab-button.icon-right:after,
.mce-plugins-tabs-buttons .tab-button.icon-top:after,
.mce-plugins-tabs-buttons .tab-button.icon-bottom:after {
    content:"";
    display: block;
    clear: both;
}

.mce-plugins-tabs-buttons .tab-button.icon-left {
    display: inline-flex;
    align-items: center;
}

.mce-plugins-tabs-buttons .tab-button.icon-left img {
    margin-right: 15px;
}

.mce-plugins-tabs-buttons .tab-button.icon-right {
    display: inline-flex;
    align-items: center;
}
.mce-plugins-tabs-buttons .tab-button.icon-right img { order: 2}
.mce-plugins-tabs-buttons .tab-button.icon-right strong { order: 1; font-weight: 400;}

.mce-plugins-tabs-buttons .tab-button.icon-top {
    display: inline-block;
    text-align: center;
}

.mce-plugins-tabs-buttons .tab-button.icon-top * {
    display: block;
}
.mce-plugins-tabs-buttons .tab-button.icon-top img {
    margin: auto;
    width: auto;
    margin-bottom: 10px;
}

.mce-plugins-tabs-buttons .tab-button.icon-bottom {
    display: grid;
    align-items: flex-end;
    text-align: center;
}

.mce-plugins-tabs-buttons .tab-button.icon-bottom img {
    order: 2;
    width: auto;
    margin: auto;
    margin-top: 10px;
}
.mce-plugins-tabs-buttons .tab-button.icon-bottom strong {
    order: 1;
    width: 100%;
    font-weight: 400;
}